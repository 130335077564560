import { Alert, Box, Button, Card } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { prepareFiles } from '../../help/slak';
import { usePost, usePut } from '../../API/request';
import { useModalBox } from '../../components/ModalBox';
import { useConfirm } from '../../components/Confirm';


const MasterAttachedFiles = ({ user, id, loadMaster }) => {
  const putU = usePut();
  const postU = usePost();
  const [newSelectedFiles, setNewSelectedFiles] = useState([]);
  const [selectUpdate, setSelectUpdate] = useState([]);
  const ModalBox = useModalBox();
  const confirm = useConfirm();


  const [alert, setAlert] = useState({
    txt: '',
    isFilesSend: false,
    isStatisticParamSend: false,
    type: 'error'
  });

  const submitFiles = ({ data, flag }) => {
    let formData = new FormData();

    if (flag === 'upload')
      data.map((el) => {
        formData.append('file', el);
      });
    else formData.append('file', data.substring(data.lastIndexOf('/') + 1));

    ModalBox({
      isVisible: true,
      headerTitle:
        flag === 'upload' ? 'Загрузка файла' : 'Редактирование файла',
      nameTitle: 'Имя файла',
      descTitle: 'Описание',
      buttonTitle: flag === 'upload' ? 'Загрузить' : 'Сохранить',
      onClick: (fileName, desc, callback) => {

        formData.append('title', fileName);
        if (desc !== '') formData.append('description', desc);


        putU(`admin/users/files/${id}`, formData)
          .then((resp) => {

            if (resp.status === 'success') {
              loadMaster();
            } else {
              throw resp
            }

            callback()

            setTimeout(() => {
              setAlert((prev) => ({
                ...prev,
                txt:
                  resp.message !== null
                    ? resp.message
                    : 'Данные успешно обновленны',
                isFilesSend: true,
                type: resp.status
              }));
            }, 1000);
          })
          .catch((err) => {
            console.log(err)
          })
      }


    });
  };

  const deleteFiles = async (url) => {
    let fileName = url.substring(url.lastIndexOf('/') + 1);

    const data = {
      forDelete: fileName
    };

    postU(`admin/users/files/${id}`, data)
      .then((resp) => {
        setAlert((prev) => ({
          ...prev,
          txt: resp.message !== null ? resp.message : 'Файл успешно удален',
          isFilesSend: true,
          type: resp.status
        }));
        loadMaster();
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          txt: err.message,
          isFilesSend: true,
          type: 'error'
        }));
      })
      .finally(() => {
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            isFilesSend: false
          }));
        }, 1000);
      });
  };

  const handlerSelectedFiles = (event) => {
    console.log(event.target.files.item(0) + ' file');

    submitFiles({
      data: [...newSelectedFiles, ...prepareFiles(event.target.files)],
      flag: 'upload'
    });

    setNewSelectedFiles((prev) => [
      ...prev,
      ...prepareFiles(event.target.files)
    ]);
  };

  const onDelete = (url) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить файл?',
      onConfirm: () => deleteFiles(url),
    });
  };


  return (
    <Card>
      <Box>
        <Alert
          severity={alert.type}
          style={{ display: alert.isFilesSend ? 'flex' : 'none' }}
        >
          {alert.txt}
        </Alert>
      </Box>

      <div
        className="info-block"
        style={{ padding: '24px', width: '100%' }}
      >
        <div className="label">Приложенные файлы:</div>
        <div>
          {user.param?.files != null &&
            user.param?.files.map((el, idx) => (
              <div
                key={idx}
                style={{
                  marginTop: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <a
                  className="files__link"
                  //href={`${process.env.REACT_APP_API_URL}/public/uploads/files/${el}`}
                  href={`${process.env.REACT_APP_API_URL}/public/uploads/files/${el.url}`}
                >
                  {el.title}
                </a>
                <div style={{ flexDirection: 'row' }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      submitFiles({ data: el.url, flag: 'redact' })
                    }
                    style={{ marginRight: 15, width: 120 }}
                  >
                    Редакт.
                  </Button>

                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => onDelete(el.url)}
                    style={{ width: 120 }}
                  >
                    Удалить
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div style={{ marginTop: '15px' }}>
          <input
            name="file"
            type="file"
            id="input__file"
            className="input input__file"
            onChange={(e) => handlerSelectedFiles(e)}
            multiple
          />
          <Button
            color="primary"
            variant="contained"
            style={{ cursor: 'pointer' }}
          >
            <label for="input__file" style={{ cursor: 'pointer' }}>
              Приложить новый файл
            </label>
          </Button>
        </div>
      </div>
    </Card>
  );
};

export  { MasterAttachedFiles };
