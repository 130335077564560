import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import ReactQuill from 'react-quill';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Testing = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);

  const [values, setValues] = useState({
    currentDateTime: '',
    timeZone: null,
    isActive: false
  });
  const [value, setValue] = useState('2023-10-25 17:21:23');
  const [serverTime, setServerTime] = useState('2023-10-25 17:21:23');

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;

    if (values.currentDateTime === '') {
      validComplete = false;
      showAlert('error', 'Поле Дата не должно быть пустым');
    }

    if (
      (typeof +values.timeZone != 'number') |
      (+values.timeZone < -12) |
      (+values.timeZone > 12)
    ) {
      validComplete = false;
      showAlert('error', 'Поле Часовой пояс должно быть числом от -12 до 12');
    }

    return validComplete;
  };

  const submit = async () => {
    if (validate()) {
      putU('global_time', {
        ...values,
        timeZone: +values.timeZone,
        isActive: !!values.isActive
      })
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные обновлены');
            updateGlobalTime();
          } else {
            showAlert('error', 'Ошибка');
          }
        })
        .catch((err) => {
          showAlert('error', 'Ошибка сервера');
        })
        .finally(() => {});
    }
  };

  const updateGlobalTime = () => {
    getU(`global_time/server_time`)
      .then((resp) => {
        if (resp.status === 'success') {
          const data = resp.data;
          setServerTime(data.time.slice(0, -5).replace('T', ' '));
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке данных, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };
  useEffect(() => {
    setIsLoaded(true);
    getU(`global_time`)
      .then((resp) => {
        if (resp.status === 'success') {
          console.log(resp.data.city);
          const data = resp.data.dateTime;
          setValues({
            ...data,
            isActive: !!data.isActive,
            currentDateTime: data.currentDateTime.slice(0, -5).replace('T', ' ')
          });
          setValue(data.currentDateTime.slice(0, -5).replace('T', ' '));
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке данных, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
    updateGlobalTime();
  }, []);

  useEffect(() => {
    console.log(values);
  }, [values]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Edit testing</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Back
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Редактирование режима тестирования" />
                <Divider />

                <CardContent sx={{ position: 'relative' }}>
                  <Typography>Время на сервере: {serverTime}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isActive}
                        onChange={() =>
                          setValues((prev) => ({
                            ...prev,
                            isActive: !prev.isActive
                          }))
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Режим тестирования"
                  />
                  <TextField
                    fullWidth
                    label="Часовой пояс"
                    margin="normal"
                    name="timeZone"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        timeZone: e.target.value
                      }))
                    }
                    type="number"
                    value={values.timeZone}
                    variant="outlined"
                    disabled={!values.isActive}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      views={[
                        'year',
                        'month',
                        'day',
                        'hours',
                        'minutes',
                        'seconds'
                      ]}
                      renderInput={(props) => <TextField {...props} />}
                      label="DateTimePicker"
                      value={value}
                      onChange={(val, input) => {
                        console.log(val, input);
                        setValue(val);
                        setValues({
                          ...values,
                          currentDateTime: input || value
                        });
                      }}
                      disabled={!values.isActive}
                      disableOpenPicker={true}
                      ampmInClock={true}
                      inputFormat="YYYY-MM-DD HH:mm:ss"
                    />
                  </LocalizationProvider>

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button color="primary" variant="contained" onClick={submit}>
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Testing;
