import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const PaymentList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([...payments]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [queryParams, setQueryParams] = useState({
    status: searchParams.get('status') || '',
    id: searchParams.get('id') || '',
    date: searchParams.get('date') || ''
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    let endpoint = `payment?page=${page + 1}&limit=${limit}`;

    setFilteredPayments(payments);

    if (queryParams.status !== '') {
      endpoint += `&status=${searchParams.get('status')}`;
      setFilteredPayments(payments.filter((el) => el.status === queryParams.status));
    }
    if (queryParams.id !== '') {
      endpoint += `&id=${searchParams.get('id')}`;
      setFilteredPayments(payments.filter((el) => el.id === Number(queryParams.id)));
    }
    if (queryParams.date !== '') {
      endpoint += `&date=${searchParams.get('date')}`;
      setFilteredPayments(payments.filter((el) => el.date.includes(queryParams.date)));
    } 
  };

  const loadPayments = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    getU(`payment?limit=999`)
    .then((resp) => {
      if (resp.status === 'success') {
        resp.data.Payment.map((el) => {
          el.date = el.date.slice(0, 10);
        });
        setPayments(resp.data.Payment);
        setFilteredPayments(resp.data.Payment);
      };
      setIsDataLoading(false);
    })
    .catch((err) => {
      setIsDataLoading(false);
    })
    .finally(() => {
      setIsLoaded(false);
    }); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadPayments();
  }, [page, limit, searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Платежи</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Платежи</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Id"
                          margin="normal"
                          name="id"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.id}
                          variant="outlined"
                          style={{ width: '10%' }}
                        />
                        <FormControl style={{width:'10%'}} sx={{ mt: 2, mb: 1 }}>
                          <InputLabel id="schedule">Статус</InputLabel>
                          <Select
                            labelId="status"
                            name="status"
                            value={queryParams.status}
                            label="Статус"
                            onChange={handleChangeQueryParams}
                          >
                              <MenuItem value={''}>
                                Все
                              </MenuItem>
                              <MenuItem value={'Успешно'}>
                                Успешно
                              </MenuItem>
                              <MenuItem value={'Неуспешно'}>
                                Неуспешно
                              </MenuItem>
                          </Select>
                        </FormControl> 
                        <TextField
                          fullWidth
                          label="Дата"
                          margin="normal"
                          name="date"
                          onChange={handleChangeQueryParams}
                          type="text"
                          value={queryParams.date}
                          variant="outlined"
                          style={{ width: '25%' }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>ID</TableCell>
                            <TableCell>ID Платежа</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Сумма</TableCell>
                            <TableCell>Дата</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPayments.slice(
                            (page*limit), (page+1)*limit
                          )?.map((payment) => (
                            <TableRow hover key={payment.id}>
                              <TableCell sx={{ width: 80 }}>
                                {payment.id}
                              </TableCell>
                              <TableCell>{payment.paymentId || '---'}</TableCell>
                              <TableCell>{payment.status || '---'}</TableCell>
                              <TableCell>{payment.payment || '---'}</TableCell>
                              <TableCell>{payment.date || '---'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={filteredPayments.length}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PaymentList;
