import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import { useSelector } from 'react-redux';
import RegularMastersList from '../../components/masters/regular-masters-list';
import OutsideMastersList from '../../components/masters/outside-masters-list';

const UserList = () => {
  // const confirm = useConfirm();
  // const getU = useGet();

  // const [searchParams, setSearchParams] = useSearchParams();
  // const [totalCount, setTotalCount] = useState(0);
  // const [isLoaded, setIsLoaded] = useState(true);
  // const [isDataLoading, setIsDataLoading] = useState(true);
  // const [masters, setMasters] = useState([]);
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const [count, setCount] = useState(0);
  // const { role } = useSelector((state) => state.auth.user);
  // const [queryParams, setQueryParams] = useState({
  //   username: searchParams.get('username') || '',
  //   fio: searchParams.get('fio') || '',
  //   email: searchParams.get('email') || '',
  //   id: searchParams.get('id') || ''
  // });

  // const handleChangeQueryParams = (event) => {
  //   setQueryParams({
  //     ...queryParams,
  //     [event.target.name]: event.target.value
  //   });
  // };

  // const handleFilterQueryParams = () => {
  //   const params = {};
  //   if (queryParams.username !== '') {
  //     params.username = queryParams.username;
  //   }
  //   if (queryParams.fio !== '') {
  //     params.fio = queryParams.fio;
  //   }
  //   if (queryParams.id !== '') {
  //     params.id = queryParams.id;
  //   }
  //   if (queryParams.email !== '') {
  //     params.email = queryParams.email;
  //   }
  //   console.log(Object.keys(params).length !== 0);
  //   if (Object.keys(params).length !== 0) {
  //     setSearchParams(params);
  //   }
  // };

  // const loadUsers = () => {
  //   setIsDataLoading(true);
  //   setIsLoaded(true);

  //   let endpoint = `admin/masters?page=${page + 1}&limit=${limit}`;

  //   if (queryParams.email !== '') {
  //     endpoint += `&email=${searchParams.get('email')}`;
  //   }
  //   if (queryParams.username !== '') {
  //     endpoint += `&username=${searchParams.get('username')}`;
  //   }
  //   if (queryParams.fio !== '') {
  //     endpoint += `&fio=${searchParams.get('fio')}`;
  //   }
  //   if (queryParams.id !== '') {
  //     endpoint += `&id=${searchParams.get('id')}`;
  //   }

  //   getU(endpoint)
  //     .then((resp) => {
  //       if (resp.status === 'success') {
  //         setMasters(resp.data.users);
  //         setCount(resp.data.currentCount || 0);
  //         setTotalCount(resp.data.totalCount);
  //       }
  //       setIsDataLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       setUsers([]);
  //       setCount(0);
  //       setIsDataLoading(false);
  //     })
  //     .finally(() => {
  //       setIsLoaded(false);
  //     });
  // };

  // useEffect(() => {
  //   if (queryParams.username === '') {
  //     searchParams.delete('username');
  //     setSearchParams(searchParams);
  //   }
  //   if (queryParams.id === '') {
  //     searchParams.delete('id');
  //     setSearchParams(searchParams);
  //   }
  //   if (queryParams.email === '') {
  //     searchParams.delete('email');
  //     setSearchParams(searchParams);
  //   }
  //   if (queryParams.fio === '') {
  //     searchParams.delete('fio');
  //     setSearchParams(searchParams);
  //   }
  // }, [queryParams]);

  // useEffect(() => {
  //   loadUsers();
  // }, [page, limit, searchParams]);
  // console.log(role);

  return (
    <>
      <Helmet>
        <title>Мастера</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '50px'
        }}
      >
        <RegularMastersList />
        <OutsideMastersList />
      </Box>
    </>
  );
};

export default UserList;
