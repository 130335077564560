import Alert from '@material-ui/core/Alert';



const AlertForm = ({ isVisible, text='Ошибка', type, style = {} }) => {
  return (
    <Alert
      severity={type}
      style={{...style, opacity: isVisible ? 1 : 0, minHeight: 48 }}
    >
      {text}
    </Alert>
  );
};

export  { AlertForm };