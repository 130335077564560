import { useEffect, useState } from 'react';

const useShowAlert = () => {
  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => setAlert({ txt: text, type, isVisible: true });
  const hideAlert = () => setAlert({ txt: '', type: 'error', isVisible: false });


  useEffect(() => {
    if (!alert.isVisible) return;
      const timer = setTimeout(() => hideAlert(), 1400);
      return () => clearTimeout(timer);
  },[alert])

  return { alert, showAlert };
}

export  { useShowAlert };
