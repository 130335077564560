import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Alert } from '@material-ui/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import InstructionSection from './InstructionSection';
import { usePut } from '../../API/request';
import {
  restrictToHorizontalAxis,
  restrictToParentElement
} from '@dnd-kit/modifiers';
const InstructionSections = ({
  containers,
  setContainers,
  recipient,
  fetchData,
  idSection,
  setLoader
}) => {
  const putU = usePut();
  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });
  const [flag, setFlag] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };
  const handleDragStart = async (e) => {
    const { active } = e;
    const find = containers.find((item) => item.id === active.id);
    console.log(find, 'find');
    if (!active) return;
    if (Array.isArray(find.instruction_ids)) {
      setContainers((prev) => {
        const updatedContainers = prev.filter(
          (container) => !find.instruction_ids.includes(container.id)
        );

        return updatedContainers;
      });
      setFlag(!flag);
    }
  };
  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!active || !over) return;

    const findElOver = containers.find((item) => item.id === over.id);
    const findElActive = containers.find((item) => item.id === active.id);

    try {
      if (!findElActive.type && !findElOver.type) {
        const res = await putU(`section/instruction/${findElOver.parent}`, {
          last_section: findElActive.parent,
          instruction_ids: [active.id]
        });
        if (res.status === 'success') setFlag(!flag);
      }
      if (!findElActive.type || !findElOver.type) {
        const params =
          findElOver.instruction_ids !== null ||
          findElOver.instruction_ids === null
            ? findElOver.id
            : findElOver.parent;

        const res = await putU(`section/instruction/${params}`, {
          last_section: findElActive.parent,
          instruction_ids: [active.id]
        });
        if (res.status === 'success') setFlag(!flag);
      }
      if (findElActive.type && !findElOver.type) {
        const res = await putU(`section/${findElActive.id}`, {
          name: findElActive.name,
          parent_section: findElOver.parent
        });
        if (res.status === 'success') setFlag(!flag);
      }
      if (findElActive.type && findElOver.type) {
        const res = await putU(`section/${findElActive.id}`, {
          name: findElActive.name,
          parent_section: findElOver.id
        });
        if (res.status === 'success') setFlag(!flag);
      }
      if (!findElActive.type && findElOver.type) {
        const res = await putU(`section/instruction/${idSection}`, {
          last_section: findElActive.parent,
          instruction_ids: [active.id]
        });
        if (res.status === 'success') setFlag(!flag);
      }
    } catch (error) {
      showAlert('error', error.message);
    }

    setContainers((containers) => {
      const activeIndex = containers.findIndex((item) => item.id === active.id);
      const overIndex = containers.findIndex((item) => item.id === over.id);
      return arrayMove(containers, activeIndex, overIndex);
    });
  };

  useEffect(() => {
    if (recipient) {
      fetchData();
    }
  }, [recipient, flag]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        overflow: 'hidden'
      }}
    >
      <Alert
        severity={alert.type}
        style={{ display: alert.isVisible ? 'flex' : 'none' }}
      >
        {alert.txt}
      </Alert>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
      >
        <SortableContext
          modifiers={[restrictToParentElement, restrictToHorizontalAxis]}
          items={containers.map((i) => i.id)}
        >
          {containers.map((container, index) => (
            <InstructionSection
              id={container.id}
              key={index}
              name={container.name}
              title={container.title}
              parentSection={container.parent_section}
              level={container.level}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default InstructionSections;
