import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const PaymentItem = ({ title, value, marker }) => {
  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: 2
      }}
    >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <Grid item>
            <Typography gutterBottom variant="h5" sx={{ textAlign: 'center' }}>
              {title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ textAlign: 'center' }}
            >
              {value}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{
                textAlign: 'center',
                color:
                  marker === 'Плохо'
                    ? 'red'
                    : marker === 'Хорошо'
                    ? 'green'
                    : 'orange'
              }}
            >
              {marker}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaymentItem;
