import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserAdd from './pages/User/UserAdd';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import MasterAdd from './pages/Master/MasterAdd';
import MasterInfo from './pages/Master/MasterInfo';
import MasterEdit from './pages/Master/MasterEdit';
import MasterList from './pages/Master/MasterList';
import MasterCompletedTicket from './pages/Master/MasterCompletedTicket';
import ManagerAdd from './pages/Manager/ManagerAdd';
import ManagerEdit from './pages/Manager/ManagerEdit';
import ManagerInfo from './pages/Manager/ManagerInfo';
import ManagerList from './pages/Manager/ManagerList';
import RegionList from './pages/Region/RegionList';
import RegionAdd from './pages/Region/RegionAdd';
import RegionEdit from './pages/Region/RegionEdit';
import TicketCategoryList from './pages/TicketCategory/TicketCategoryList';
import TicketCategoryAdd from './pages/TicketCategory/TicketCategoryAdd';
import TicketCategoryEdit from './pages/TicketCategory/TicketCategoryEdit';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import SettingsExampl from './pages/Settings/SettingsExampl';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import ConfigurationKeysList from './pages/ConfigurationKeys/ConfigurationKeysList';
import ConfigurationKeysAdd from './pages/ConfigurationKeys/ConfigurationKeysAdd';
import ConfigurationKeysEdit from './pages/ConfigurationKeys/ConfigurationKeysEdit';
import AppLogs from './pages/AppWork/AppLogs';
import AppStatistics from './pages/AppStatistics/AppStatistics';
import AppStatisticsEventsList from './pages/AppStatistics/AppStatisticsEventsList';
import AppStatisticsEventsAdd from './pages/AppStatistics/AppStatisticsEventsAdd';
import AppStatisticsEventsEdit from './pages/AppStatistics/AppStatisticsEventsEdit';
import Schedule from './pages/Schedule/Schadule';
import PaymentList from './pages/AllPayments/PaymentList';
import InstructionList from './pages/Instruction/instructionList';
import InstructionAdd from './pages/Instruction/instructionAdd';
import InstructionInfo from './pages/Instruction/instructionInfo';
import InstructionEdit from './pages/Instruction/instructionEdit';
import MessageList from './pages/Message/messageList';
import MessageAdd from './pages/Message/messageAdd';
import MessageInfo from './pages/Message/messageInfo';
import MessageEdit from './pages/Message/messageEdit';
import Testing from './pages/Testing/Testing';
import InstructionSelect from './pages/InstructionHierarchy/instructionSelect';
import InstructionHierarchy from './pages/InstructionHierarchy/InstructionHierarchy';
import Orders from './pages/orders/orders';
import { element } from 'prop-types';
import MasterOutsideAdd from './pages/Master/master-outside/master-outside-add/master-outside-add';
import MasterOutsideEdit from './pages/Master/master-outside/master-outside-edit';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'orders', element: <Orders /> },
      { path: 'instruction', element: <InstructionList /> },
      { path: 'instruction/add', element: <InstructionAdd /> },
      { path: 'instruction/:id', element: <InstructionInfo /> },
      { path: 'instruction/edit/:id', element: <InstructionEdit /> },
      { path: 'instruction-select', element: <InstructionSelect /> },
      { path: 'instruction-hierarchy', element: <InstructionHierarchy /> },
      { path: 'message', element: <MessageList /> },
      { path: 'message/add', element: <MessageAdd /> },
      { path: 'message/:id', element: <MessageInfo /> },
      { path: 'message/edit/:id', element: <MessageEdit /> },
      // { path: 'user/:id', element: <UserInfo /> },
      // { path: 'user/edit/:id', element: <UserEdit /> },
      // { path: 'user/add', element: <UserAdd /> },
      // { path: 'users', element: <UserList /> },

      { path: 'master/:id', element: <MasterInfo /> },
      { path: 'master/edit/:id', element: <MasterEdit /> },
      { path: 'master/add', element: <MasterAdd /> },
      { path: 'masters', element: <MasterList /> },
      { path: 'outside/add', element: <MasterOutsideAdd /> },
      { path: 'outside/edit/:id', element: <MasterOutsideEdit /> },
      {
        path: 'master/:id/completedTicket/:ticketId',
        element: <MasterCompletedTicket />
      },
      { path: 'schedule/edit/:id', element: <Schedule /> },

      { path: 'manager/:id', element: <ManagerInfo /> },
      { path: 'manager/edit/:id', element: <ManagerEdit /> },
      { path: 'manager/add', element: <ManagerAdd /> },
      { path: 'managers', element: <ManagerList /> },

      { path: 'regions', element: <RegionList /> },
      { path: 'regions/add', element: <RegionAdd /> },
      { path: 'regions/edit/:id', element: <RegionEdit /> },

      { path: 'ticketCategory', element: <TicketCategoryList /> },
      { path: 'ticketCategory/add', element: <TicketCategoryAdd /> },
      { path: 'ticketCategory/edit/:id', element: <TicketCategoryEdit /> },

      { path: 'payments', element: <PaymentList /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'app-logs', element: <AppLogs /> },

      { path: 'app-statistics', element: <AppStatistics /> },
      { path: 'app-statistics/events', element: <AppStatisticsEventsList /> },
      {
        path: 'app-statistics/events/add',
        element: <AppStatisticsEventsAdd />
      },
      {
        path: 'app-statistics/events/edit/:id',
        element: <AppStatisticsEventsEdit />
      },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'settings', element: <SettingsExampl /> },
      { path: 'testing', element: <Testing /> },

      { path: 'configuration/keys', element: <ConfigurationKeysList /> },
      { path: 'configuration/keys/add', element: <ConfigurationKeysAdd /> },
      {
        path: 'configuration/keys/edit/:id',
        element: <ConfigurationKeysEdit />
      },

      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
