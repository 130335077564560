export const initValues = {
  phone: '',
  username: '',
  fio: '',
  generalCompensation: null,
  schedule: 'Без ограничений',
  beginSchedule: '',
  timeBegin: '00:00',
  timeEnd: '23:00',
  cities: [],
  bet: null,
  tags: [],
  lift_compensation: null,
  full_time: false,
  telegram: '',
  whatsApp: '',
}
export const initError = {
  phone: false,
  username: false,
  fio: false,
  telegram: false,
  whatsApp: false,
  beginSchedule: false,
  timeBegin: false,
  timeEnd: false,
  bet: false,
  cities: false,
  generalCompensation: false,
  lift_compensation: false,
  tags: false,
}
