import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import ReactQuill from 'react-quill';

const MessageInfo = () => {
  const navigate = useNavigate();
  const getI = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [instruction, setInstruction] = useState({});

  const setClassNameForImgTag = () => {
    const allElements = document
      .querySelector('.content__wr')
      .querySelectorAll('img');

    console.log(allElements);
    allElements.forEach((elem) => {
      elem.setAttribute('class', 'image__from__ckeditor');
    });

    console.log(allElements);
  };

  const valueForSelect = useMemo(() => {
    if (instruction.recipient === 0) return 'Всем';
    else if (instruction.recipient === 1) return 'Менеджеры';
    else if (instruction.recipient === 2) return 'Исполнители';
    else return '';
  }, [instruction.recipient]);

  useEffect(() => {
    setIsLoaded(true);
    getI(`message/certain/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setInstruction(resp.data.Message);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
        setClassNameForImgTag();
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Сообщения</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Сообщения
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Сообщения</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{instruction?.id || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Заголовок сообщения:</div>
                      <div className="text">{instruction?.title || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Получатель:</div>
                      <div className="text">{valueForSelect}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Дата создания:</div>
                      <div className="text">
                        {instruction?.date_create.slice(0, 10) || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Дата обновления:</div>
                      <div className="text">
                        {instruction?.date_update.slice(0, 10) || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Содержание:</div>
                    </div>
                    <div
                      className="content__wr"
                      dangerouslySetInnerHTML={{ __html: instruction.data }}
                    ></div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default MessageInfo;
