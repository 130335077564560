import { configureStore } from '@reduxjs/toolkit';
import auth from './slices/auth';
import recipient from './slices/recipient';
import typeMaster from './slices/type-master';
export const store = configureStore({
  reducer: {
    auth,
    recipient,
    typeMaster
  }
});
