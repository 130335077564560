import { createSlice } from '@reduxjs/toolkit';

export const typeSlice = createSlice({
  name: 'type',
  initialState: {
    type: ''
  },
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    }
  }
});

export const { setType } = typeSlice.actions;

export default typeSlice.reducer;
