import { Box, Typography } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import { ArrowDownward } from '@material-ui/icons';
const InstructionSection = ({
  name,
  id,
  level,
  title,
  parentSection,
  type
}) => {
  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: id
  });
  const isMainElement = parentSection === null;
  return (
    <Box
      sx={{
        marginLeft: `${level * 30}px`,
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}
    >
      {!isMainElement && (
        <Typography
          sx={{
            background: title ? '#fff' : '#f0f0f0',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={{
            opacity: isDragging ? 0.5 : 1,
            cursor: 'grab',
            transition,
            transform: CSS.Translate.toString(transform)
          }}
        >
          {name || title}
          {!type && !isMainElement && !title && <ArrowDownward />}
        </Typography>
      )}
    </Box>
  );
};

export default InstructionSection;
