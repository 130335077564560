import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Mail as MailIcon,
  DollarSign as Payment,
  Settings,
  Activity,
  ChevronUp,
  ChevronsUp,
  Gift,
  Shield,
  Grid,
  Bookmark,
  Book,
  AlertOctagon,
  File,
  FileText,
  List as ListIcon,
  PhoneForwarded,
  Monitor,
  Info,
  Globe,
  Terminal,
  Clipboard
} from 'react-feather';
import NavItem from './NavItem';
import { useGet } from '../../API/request';
import { useSelector } from 'react-redux';

const itemsDesign = [
  // {
  //   href: '/app/dashboard',
  //   icon: BarChartIcon,
  //   title: 'Статистика'
  // },
  // {
  //   href: '/app/users',
  //   icon: UsersIcon,
  //   title: 'Пользователи'
  // },
  {
    href: '/app/masters',
    icon: UsersIcon,
    title: 'Мастера'
  },
  {
    href: '/app/managers',
    icon: UsersIcon,
    title: 'Менеджеры'
  },
  {
    href: '/app/payments',
    icon: Payment,
    title: 'Платежи'
  },
  {
    href: '/app/regions',
    icon: Globe,
    title: 'Регионы'
  },
  {
    href: '/app/ticketCategory',
    icon: Activity,
    title: 'Категории'
  },
  {
    href: '/app/orders',
    icon: Clipboard,
    title: 'Заказы'
  }
];

const itemsSystem = [
  {
    href: '/app/feedbacks',
    icon: File,
    title: 'Обратная связь'
  },
  {
    icon: Info,
    title: 'Инструкции',
    list: [
      {
        href: '/app/instruction',
        icon: Settings,
        title: 'Инструкции'
      },
      {
        href: '/app/instruction-select',
        icon: Settings,
        title: 'Иерархия инструкций'
      }
    ]
  },
  {
    href: '/app/message',
    icon: FileText,
    title: 'Центр сообщений'
  },
  {
    icon: MailIcon,
    title: 'Шаблоны Почты',
    list: [
      {
        href: '/app/email-templates',
        icon: Settings,
        title: 'Шаблоны'
      },
      {
        href: '/app/email-history',
        icon: Settings,
        title: 'История'
      }
    ]
  },
  {
    href: '/app/settings',
    icon: Settings,
    title: 'Настройки',
    list: [
      {
        href: '/app/settings',
        icon: Settings,
        title: 'Настройки'
      },
      {
        href: '/app/configuration/keys',
        icon: Settings,
        title: 'Конфигуратор ключей'
      }
    ]
  },
  {
    icon: Monitor,
    title: 'Работа приложения',
    list: [
      {
        href: '/app/app-logs',
        icon: Settings,
        title: 'Логи ошибок'
      }
      // {
      //   href: '/app/app-statistics',
      //   icon: Settings,
      //   title: 'Статистика'
      // }
    ]
  },
  {
    icon: AlertOctagon,
    title: 'Системные',
    list: [
      {
        href: '/app/common-settings',
        icon: Settings,
        title: 'Общие настройки'
      },
      {
        href: '/app/logs',
        icon: Settings,
        title: 'Логи'
      },
      {
        href: '/app/update-system',
        icon: Settings,
        title: 'Обновление системы'
      }
    ]
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const { username, avatar, role } = useSelector((state) => state?.auth.user);
  const [sideBarDataDesign, setSideBarDataDesign] = useState(itemsDesign);
  const [sideBarDataSystem, setSideBarDataSystem] = useState(itemsSystem);

  const elementsToRemoveDesign = [
    'Менеджеры',
    'Платежи',
    'Регионы',
    'Категории',
    'Заказы'
  ];
  const elementsToRemoveSystem = [
    'Обратная связь',
    'Шаблоны Почты',
    'Настройки',
    'Работа приложения',
    'Системные'
  ];
  // Фильтруем элементы в массивах itemsDesign и itemsSystem
  useEffect(() => {
    if (role === 'executive_manager') {
      setSideBarDataDesign(
        itemsDesign.filter(
          (item) => !elementsToRemoveDesign.includes(item.title)
        )
      );
      setSideBarDataSystem(
        itemsSystem.filter(
          (item) => !elementsToRemoveSystem.includes(item.title)
        )
      );
    }
  }, [role]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={`${process.env.REACT_APP_API_URL}/public/uploads/avatars/${avatar}`}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
            marginBottom: 1
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {username || 'Admin'}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {sideBarDataDesign.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item?.list}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {sideBarDataSystem.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item?.list}
            />
          ))}
          {role === 'super' && (
            <NavItem
              href={'/app/testing'}
              key={'Тестирование'}
              title={'Тестирование'}
              icon={Terminal}
              // list={item?.list}
            />
          )}
        </List>
      </Box>
      <Typography
        color="textPrimary"
        variant="body2"
        sx={{ textAlign: 'center' }}
      >
        v{process.env.REACT_APP_VERSION} {process.env.REACT_APP_STATUS}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
