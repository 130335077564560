import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid,
  Checkbox,
  ListItemText,
  FormControlLabel
} from '@material-ui/core';
import { formatPhoneNumber } from '../../../help/telephone-mask';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGet, usePut } from '../../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../../styles/Avatar/style.css';
import { workingScheduleOutside, roles } from '../../../Constants';
import { Loader } from '../../../components/Loader/Loader';
import { HelmetMain } from '../../../components/Helmet/Helmet';

const MasterOutsideEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();
  const [cities, setCities] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);
  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    phone: '',
    username: '',
    fio: '',
    img: '',
    beginSchedule: '',
    cities: [],
    schedule: '',
    tags: [],
    timeBegin: '00:00',
    timeEnd: '23:00',
    bet: null,
    generalCompensation: null,
    lift_compensation: null,
    full_time: false,
    role: 'freelance_master',
    telegram: '',
    whatsApp: '',
  });

  const [errors, setErrors] = useState({
    phone: false,
    username: false,
    fio: false,
    img: false,
    beginSchedule: '',
    cities: false,
    schedule: false,
    tags: false,
    timeBegin: false,
    timeEnd: false,
    bet: false,
    generalCompensation: false,
    lift_compensation: false,
    full_time: false,
    telegram: false,
    whatsApp: false
  });
  const [tags, setTags] = useState([]);
  const [alert, setAlert] = useState({
    txt: '',
    isAvatarVisible: false,
    isInfoVisible: false,
    isPasswordVisible: false,
    type: 'error'
  });


  const handleChange = (event) => {
    setNothingChanged(false);
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrors({...errors, [event.target.name]: false });

    if (event.target.name === 'generalCompensation' && event.target.value > 100_000) {
      setValues({ ...values, [event.target.name]: '100000' });
    }
    if (event.target.name === 'bet' && event.target.value > 100) {
      setValues({ ...values, [event.target.name]: '100' });
    }
    if (event.target.name === 'lift_compensation' && event.target.value > 100) {
      setValues({ ...values, [event.target.name]: '100' });
    }
  };

  const showAlert = (type, text, isVisible) => {
    setAlert({
      txt: text,
      type,
      [isVisible]: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        [isVisible]: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };

  const handleChangePhone = (event) => {
    setNothingChanged(false);
    const formattedNumber = formatPhoneNumber(event.target.value);
    if (formattedNumber.length > 16) return;
    setValues({ ...values, phone: formattedNumber });
    setErrors({ ...errors, phone: false });
  };


  const avaUploaded = (event) => {
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      img: event.target.files[0]
    });
  };

  const submitAvatar = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('avatar', values.img);
    console.log(values.img);

    putU(`admin/users/avatar/${id}`, data)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновлены', 'isAvatarVisible');
        } else {
          showAlert('error', resp.message, 'isAvatarVisible');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера', 'isAvatarVisible');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };


  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };
    let alertText = null;

    const changeAlertText = (text, field) => {
      if (validComplete) validComplete = false;
      if (field) {
        formErrors[field] = true;
      }
      if (!alertText) {
        alertText = text;
      };
    };

    if (values.username.length === 0) {
      changeAlertText('Поле User Name не должно быть пустым', 'username');
    }

    if (values.fio.length === 0) {
      changeAlertText('Поле FIO не должно быть пустым', 'fio');
    }

    if (values.phone.length === 0) {
      changeAlertText('Поле phone number не должно быть пустым', 'phone');
    }

    if (values.telegram.length === 0 && values.whatsApp.length === 0) {
      changeAlertText('Поле whatsApp или telegram не должно быть пустым', 'telegram');
      changeAlertText('Поле whatsApp или telegram не должно быть пустым' , 'whatsApp');
    }

    if (!values.generalCompensation) {
      changeAlertText('Поле Общая компенсация не должно быть пустым', 'generalCompensation');
    } else if (values.generalCompensation && Number(values.generalCompensation) > 100_000) {
      changeAlertText('Поле Общая компенсация не должно превышать 100 000', 'generalCompensation');
    }

    if (values.beginSchedule.length === 0) {
      changeAlertText('Поле Дата не должно быть пустым', 'beginSchedule');
    }

    if (!values.bet) {
      changeAlertText('Поле Процент не должно быть пустым', 'bet');
    } else if (values.bet && Number(values.bet) > 100) {
      changeAlertText('Поле Процент не должно превышать 100', 'bet');
    }

    if (!values.lift_compensation) {
      changeAlertText('Поле Процент с допродаж не должно быть пустым', 'lift_compensation');
    } else if (values.lift_compensation && Number(values.lift_compensation) > 100) {
      changeAlertText('Поле Общая компенсация не должно превышать 100', 'lift_compensation');
    }

    if (values.cities.length === 0) {
      changeAlertText('Поле Города не должно быть пустым', 'cities');
    }

     if (alertText) showAlert('error', alertText);

    setErrors(formErrors);
    return validComplete;
  };

  const getCurrentMaster = () => {
    return getU(`admin/masters/freelance/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          return resp.data.master;
        } else {
          throw new Error('Failed get current master')
        }
      })
  }


  const submitInfo = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений', 'isInfoVisible');
      return;
    }

    if (!validate()) return;

    setSubmitDisabled(true);

    const dataForUsers = {
      username: values.username,
      role: 'freelance_master'
    };



    const tagsParams = values.tags.length === 0 ? tags.join(',') : values.tags.join(',');

    const dataForParams = {
      schedule:
        values.schedule === 'Без ограничений' ? null : values.schedule,
      fio: values.fio,
      generalCompensation: values.generalCompensation,
      beginSchedule: values.beginSchedule,
      timeBegin: values.timeBegin,
      timeEnd: values.timeEnd,
      cities: values.cities.join(','),
      tags: tagsParams,
      bet: values.bet,
      lift_compensation: values.lift_compensation,
      IgnoreTimeRange: values.full_time,
      Telegram: values.telegram !== '' ? values.telegram : null,
      WhatsApp: values.whatsApp !== '' ? values.whatsApp : null,
      phone:
        values.phone.length > 16
          ? values.phone.substring(0, values.phone.length - 1)
          : values.phone
    };

    putU(`admin/users/${id}`, dataForUsers)
      .then((resp) => {
        if (resp.status === 'error') {
          showAlert('error', `Ошибка: ${resp.message}`, 'isInfoVisible');
        }
      })
      .catch((err) => {
        showAlert('error', `Ошибка сервера: ${err.message}`, 'isInfoVisible');
      })


    putU(`admin/users/freelance/param/${id}`, dataForParams)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновлены', 'isInfoVisible');
        } else {
          showAlert('error', `Ошибка: ${resp.message}`, 'isInfoVisible');
        }
      })
      .catch((err) => {
        showAlert('error', `Ошибка сервера: ${err.message}`, 'isInfoVisible');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });

    if (values.tags.length === 0) {
      getCurrentMaster()
        .then(data => {
          const userShedule = data.param.schedule === "24/7" ? 'Без ограничений' : data.param.schedule;

          setValues({
            ...values,
            phone: data.param.phone ? data.param.phone : '',
            username: data.username ? data.username : '',
            fio: data.param.fio ? data.param.fio : '',
            img: data.avatar,
            beginSchedule:
              data.param.beginSchedule !== null
                ? new Date(data.param.beginSchedule).toISOString().split('T')[0]
                : [],
            cities:
              data.param.cities !== null ? data.param.cities.split(',') : [],
            schedule: userShedule,
            tags: data.param.tags !== null ? data.param.tags.split(',') : [],
            timeBegin: data.param.timeBegin,
            timeEnd: data.param.timeEnd,
            bet: data.param.bet,
            generalCompensation: Number(data.param.generalCompensation).toFixed(),
            lift_compensation: data.param.lift_compensation,
            full_time: data.param.IgnoreTimeRange === 1,
            telegram: data.param.Telegram ? data.param.Telegram : '',
            whatsApp: data.param.WhatsApp ? data.param.WhatsApp : '',
          });
        })
        .catch((err) => showAlert(
          'error',
          `Произошла ошибка при загрузке навыков: ${err.message}`
        ))
    }
  };


  const fetchRegion = () => {
    return getU('region')
      .then((resp) => {
        if (resp.status === 'success') {
          return resp.data.region;
        }
      })
  }

  const fetchTicketCategory = () => {
    return getU('ticket_category')
      .then((resp) => {
        if (resp.status === 'success') {
          return resp.data.category;
        }
      })
  }



  useEffect(() => {

    fetchRegion()
      .then((regions) => setCities(regions.map((city) => city.region_name)))
      .catch((err) => showAlert(
        'error',
        `Произошла ошибка при загрузке городов: ${err.message}`
      ));

    fetchTicketCategory()
      .then((category) => setTags(category.map((tag) => tag.category_name)))
      .catch((err) => showAlert(
        'error',
        `Произошла ошибка при загрузке навыков: ${err.message}`
      ))

    getCurrentMaster()
      .then(data => {
        const userShedule = data.param.schedule === "24/7" ? 'Без ограничений' : data.param.schedule;

        setValues({
          ...values,
          phone: data.param.phone ? data.param.phone : '',
          username: data.username ? data.username : '',
          fio: data.param.fio ? data.param.fio : '',
          img: data.avatar,
          beginSchedule:
            data.param.beginSchedule !== null
              ? new Date(data.param.beginSchedule).toISOString().split('T')[0]
              : [],
          cities:
            data.param.cities !== null ? data.param.cities.split(',') : [],
          schedule: userShedule,
          tags: data.param.tags !== null ? data.param.tags.split(',') : [],
          timeBegin: data.param.timeBegin,
          timeEnd: data.param.timeEnd,
          bet: data.param.bet,
          generalCompensation: Number(data.param.generalCompensation).toFixed(),
          lift_compensation: data.param.lift_compensation,
          full_time: data.param.IgnoreTimeRange === 1,
          telegram: data.param.Telegram ? data.param.Telegram : '',
          whatsApp: data.param.WhatsApp ? data.param.WhatsApp : '',
        });
      })
      .catch((err) => showAlert(
        'error',
        `Произошла ошибка при загрузке навыков: ${err.message}`
      ))
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);


  if (isLoaded) return (<Loader/>);

  return (
    <>
      <HelmetMain title='Редактирование пользователя' />
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Мастера
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Мастера</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
        <Box sx={{ marginLeft: 2 }}>
          <Link to={`/app/schedule/edit/${id}`}>
            <Button color="primary" variant="contained">
              Редактирование графика
            </Button>
          </Link>
        </Box>
      </Box>
      {/*image*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isAvatarVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование аватарки" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => avaUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img
                          src={
                            values.img !== '' && typeof values.img === 'string'
                              ? `https://rapplegroup.backend.wtsdemo.ru/public/uploads/avatars/${values.img}`
                              : uploadedImg
                          }
                          className="itemImg"
                        />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAvatar}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      {/*info*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isInfoVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование пользователя" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Имя пользователя"
                    margin="normal"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    error={errors.username}
                  />
                  <TextField
                    fullWidth
                    label="ФИО"
                    margin="normal"
                    name="fio"
                    onChange={handleChange}
                    type="text"
                    value={values.fio}
                    variant="outlined"
                    error={errors.fio}
                  />
                  <TextField
                    fullWidth
                    label="+7 (900)-000-00-00"
                    margin="normal"
                    name="phone"
                    onChange={handleChangePhone}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    error={errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="Whats-app"
                    margin="normal"
                    name="whatsApp"
                    onChange={handleChange}
                    type="text"
                    value={values.whatsApp}
                    variant="outlined"
                    error={errors.whatsApp}
                  />
                  <TextField
                    fullWidth
                    label="Telegram"
                    margin="normal"
                    name="telegram"
                    onChange={handleChange}
                    type="text"
                    value={values.telegram}
                    variant="outlined"
                    error={errors.telegram}
                  />
                  <TextField
                    fullWidth
                    label="Общая компенсация"
                    margin="normal"
                    name="generalCompensation"
                    onChange={handleChange}
                    type="number"
                    value={values.generalCompensation}
                    variant="outlined"
                    error={errors.generalCompensation}
                    inputProps={{
                      min: '0'
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Рабочий график</InputLabel>
                    <Select
                      labelId="Schedule"
                      name="schedule"
                      value={values.schedule}
                      label="Рабочий график"
                      onChange={handleChange}
                    >
                      {workingScheduleOutside?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: 250, mr: 5 }}
                    margin="normal"
                    id="beginSchedule"
                    label="Дата начала отсчета графика"
                    type="date"
                    name="beginSchedule"
                    value={values.beginSchedule}
                    onChange={handleChange}
                    // defaultValue={`${new Date().getFullYear()}-${
                    //   new Date().getMonth() + 1 < 10 ? '0' : ''
                    // }${new Date().getMonth() + 1}-${new Date().getDate()}`}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '9999-12-31'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeBegin"
                    label="Время начала"
                    type="time"
                    name="timeBegin"
                    onChange={handleChange}
                    value={values.timeBegin ? values.timeBegin : ''}
                    error={errors.timeBegin}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeEnd"
                    label="Время окончания"
                    type="time"
                    name="timeEnd"
                    onChange={handleChange}
                    value={values.timeEnd ? values.timeEnd : ''}
                    error={errors.timeEnd}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '1'
                    }}
                  />
                  <TextField
                    sx={{ width: 100, mr: 5 }}
                    margin="normal"
                    id="bet"
                    label="Процент %"
                    type="number"
                    name="bet"
                    value={values.bet}
                    onChange={handleChange}
                    error={errors.bet}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 175 }}
                    margin="normal"
                    id="lift_compensation"
                    label="Процент с допродаж %"
                    type="number"
                    name="lift_compensation"
                    value={values.lift_compensation}
                    error={errors.lift_compensation}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="cities">Города</InputLabel>
                    <Select
                      labelId="cities"
                      name="cities"
                      value={values.cities}
                      label="Города"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                      error={errors.cities}
                    >
                      {cities?.map((city, i) => (
                        <MenuItem value={city} key={i}>
                          <Checkbox
                            checked={
                              city === values.cities.find((el) => el === city)
                            }
                          />
                          <ListItemText primary={city} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="tags">Навыки</InputLabel>
                    <Select
                      labelId="tags"
                      name="tags"
                      value={values.tags}
                      label="Навыки"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                      error={errors.tags}
                    >
                      {tags?.map((tag, i) => (
                        <MenuItem value={tag} key={i}>
                          <Checkbox
                            checked={
                              tag === values.tags.find((el) => el === tag)
                            }
                          />
                          <ListItemText primary={tag} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.full_time}
                        onChange={() => {
                          setValues((prev) => ({
                            ...prev,
                            full_time: !prev.full_time
                          }));
                          setNothingChanged(false);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Не учитывать временной диапазон"
                    labelPlacement="bottom"
                  />
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitInfo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MasterOutsideEdit;
