import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { Link, useSearchParams } from 'react-router-dom';
import { useGet, useDelete } from '../../API/request';
import { useSelector } from 'react-redux';
import SearchMasters from './search-masters';
import { useConfirm } from '../../components/Confirm/index';
import MastersTable from './masters-table';

const OutsideMastersList = () => {
  const confirm = useConfirm();
  const [masters, setMasters] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const getU = useGet();
  const deleteU = useDelete();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { role } = useSelector((state) => state.auth.user);
  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const [queryParams, setQueryParams] = useState({
    username: searchParams.get('username') || '',
    fio: searchParams.get('fio') || '',
    email: searchParams.get('email') || '',
    id: searchParams.get('id') || ''
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.username !== '') {
      params.username = queryParams.username;
    }
    if (queryParams.fio !== '') {
      params.fio = queryParams.fio;
    }
    if (queryParams.id !== '') {
      params.id = queryParams.id;
    }
    if (queryParams.email !== '') {
      params.email = queryParams.email;
    }
    console.log(Object.keys(params).length !== 0);
    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    } else {
      setSearchParams({});
    }
  };
  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить пользователя?',
      onConfirm: () => {
        deleteU(`admin/users/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadUsers();
            }
          })
          .catch((e) => {
            console.log('opened');
            console.log(e.response);
          });
      }
    });
  };
  const loadUsers = () => {
    setIsDataLoading(true);
    console.log(queryParams.fio);
    let endpoint = `admin/masters/freelance?page=${page + 1}&limit=${limit}`;

    if (queryParams.email !== '') {
      endpoint += `&region=${searchParams.get('email')}`;
    }
    if (queryParams.username !== '') {
      endpoint += `&username=${searchParams.get('username')}`;
    }
    if (queryParams.fio !== '') {
      endpoint += `&fio=${searchParams.get('fio')}`;
    }
    if (queryParams.id !== '') {
      endpoint += `&id=${searchParams.get('id')}`;
    }
    console.log('endpoint before fetching:', endpoint);

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setMasters(resp.data.users);
          setTotalCount(resp.data.totalCount);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };
  useEffect(() => {
    loadUsers();
  }, [page, limit, searchParams]);
  return (
    <Box sx={{ backgroundColor: 'background.default', py: 3 }}>
      <Container maxWidth={false}>
        {isDataLoading ? (
          <UserListSkelet />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <Box className="headerTitle">Сторонние мастера</Box>
              <Box sx={{ marginLeft: 2 }}>
                <Link to="/app/outside/add">
                  <Button color="primary" variant="contained">
                    Добавить стороннего мастера
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Card>
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 1400 }}>
                    <SearchMasters
                      queryParams={queryParams}
                      handleChangeQueryParams={handleChangeQueryParams}
                      handleFilterQueryParams={handleFilterQueryParams}
                    />
                    <Divider />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: 80 }}>ID</TableCell>
                          <TableCell>Аватар</TableCell>
                          <TableCell>Имя пользователя</TableCell>
                          <TableCell>ФИО</TableCell>
                          <TableCell>Номер телефона</TableCell>
                          <TableCell>Регион</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <MastersTable
                        limit={limit}
                        page={page}
                        outside={true}
                        handleChangeLimit={handleChangeLimit}
                        handleChangePage={handleChangePage}
                        masters={masters}
                        onDelete={onDelete}
                        totalCount={totalCount}
                        role={role}
                      />
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default OutsideMastersList;
