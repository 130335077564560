import { Fragment, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import moment from 'moment-timezone';
import localization from 'moment/locale/ru';
import { usePost } from '../../API/request';

const GraphBlock = ({
  block,
  disabledHours,
  setDisabledHours,
  requests,
  setRequests
}) => {
  // console.log(JSON.stringify(block));
  const postU = usePost();
  moment.localeData('ru', localization);

  const pushNewRequest = async (obj) => {
    let newRequests = [...requests];
    newRequests.push(obj);
    setRequests(newRequests);
  };

  const changeSlot = (action, hourData) => {
    let newDisabledHours = [];

    if (action === 'add') {
      newDisabledHours = [...disabledHours];
      newDisabledHours.push(hourData.time);
    } else {
      newDisabledHours = [...disabledHours.filter((a) => a !== hourData.time)];
    }

    setDisabledHours(newDisabledHours);
  };

  const processClickLogic = (hourData) => {
    if (disabledHours.includes(hourData.time)) {
      console.log('remove hour');
      changeSlot('remove', hourData);
    } else {
      console.log('add hour');
      changeSlot('add', hourData);
    }

    pushNewRequest({ toDo: 'triggerHour', data: hourData.time });
  };

  return (
    <Paper>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          {block.map((day) => {
            return (
              <Fragment>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell
                    padding="none"
                    style={{
                      border: '1px solid #5664D2',
                      padding: 11,
                      whiteSpace:'nowrap'
                    }}
                    size="small"
                    rowSpan={4}
                    onClick={() => {
                      console.log(new Date(day.date).getDate());
                      let newDisabledHours = [...disabledHours];

                      console.log(JSON.stringify(day));

                      day.hours.map((hourData) => {
                        if (!disabledHours.includes(hourData.time)) {
                          day.hours.map((hourData) => {
                            newDisabledHours.push(hourData.time);
                          });
                        } else {
                          day.hours.map((hourData) => {
                            console.log(hourData.time);
                            newDisabledHours = [
                              ...newDisabledHours.filter(
                                (a) => a !== hourData.time
                              )
                            ];
                          });
                        }
                      });

                      setDisabledHours(newDisabledHours);

                      pushNewRequest({
                        toDo: 'triggerDay',
                        data: moment(day.date).format('YYYY-MM-DD')
                      });
                    }}
                  >
                    {moment(day.date).format('dd') +
                      ' ' +
                      moment(day.date).format('DD.MM')}
                  </TableCell>
                </TableRow>

                <TableRow>
                  {day.hours.map((hourData) => {
                    if (hourData.id + 1 <= 12)
                      return (
                        <TableCell
                          size="small"
                          padding="none"
                          style={{
                            border: '1px solid #5664D2',
                            padding: 12,
                            alignItems: 'center',
                            textAlign: 'center',
                            backgroundColor: disabledHours.includes(
                              hourData.time
                            )
                              ? '#BDBDBD'
                              : 'white'
                          }}
                          onClick={(e) => {
                            processClickLogic(hourData);
                          }}
                        >
                          {hourData.id + 1}
                        </TableCell>
                      );
                  })}
                </TableRow>

                <TableRow style={{ width: 200 }}>
                  {day.hours.map((hourData) => {
                    if (hourData.id + 1 > 12)
                      return (
                        <TableCell
                          size="small"
                          padding="none"
                          style={{
                            border: '1px solid #5664D2',
                            padding: 12,
                            alignItems: 'center',
                            textAlign: 'center',
                            backgroundColor: disabledHours.includes(
                              hourData.time
                            )
                              ? '#BDBDBD'
                              : 'white'
                          }}
                          onClick={(e) => {
                            processClickLogic(hourData);
                          }}
                        >
                          {hourData.id + 1}
                        </TableCell>
                      );
                  })}
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default GraphBlock;
