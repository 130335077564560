import { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

const MasterCompletedTicket = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { id, ticketId } = useParams();
  const getU = useGet();
  const [ticket, setTicket] = useState({});
  const [imgTicket, setImgTicket] = useState([]);

  useLayoutEffect(() => {
    getU(`completed_ticket/current/${ticketId}`).then((resp) => {
      resp.data.completeInfo.img.map((el) => {
        if (el.includes('/public/uploads/images/')) {
          setImgTicket((prev) => [
            ...prev,
            el.split('/public/uploads/images/').pop()
          ]);
        } else setImgTicket((prev) => [...prev, el]);
      });

      setTicket({
        completeInfo: resp.data.completeInfo,
        manager: resp.data.manager,
        master: resp.data.master,
        ticket: resp.data.ticket
      });
    });
  }, []);

  return (
    <>
      {Object.keys(user).length !== 0 && (
        <>
          {' '}
          <Helmet>
            <title>Сompleted Ticket</title>
          </Helmet>
          <Box className="headerWrapper">
            <Box className="headerTitle">
              <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                Назад
              </Button>
              Выполненный заказ {ticket?.ticket?.prettier_id}
            </Box>
            <ul className="headerList">
              <li onClick={() => navigate(-2)}>Мастера</li>
              <li>/</li>
              <li onClick={() => navigate(-1)}>Мастер {id}</li>
              <li>/</li>
              <li>Выполненный заказ</li>
            </ul>
          </Box>
        </>
      )}

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <Box className="wrapAvatar">
                  <Box className="info-block">
                    {/* Информация о тикете  */}
                    <Box
                      className={'label'}
                      style={{
                        fontSize: '23px',
                        margin: '15px 0'
                      }}
                    >
                      Информация о тикете
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Адресс:</Box>
                      <Box className="text">
                        {ticket?.ticket?.address || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Категория:</Box>
                      <Box className="text">
                        {ticket?.ticket?.category || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Имя клиента:</Box>
                      <Box className="text">
                        {ticket?.ticket?.client_name || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Описание:</Box>
                      <Box className="text">
                        {ticket?.ticket?.description || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Номер телефона:</Box>
                      <Box className="text">
                        {ticket?.ticket?.phone || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Цена:</Box>
                      <Box className="text">
                        {ticket?.ticket?.price || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Дорожная стоимость:</Box>
                      <Box className="text">
                        {ticket?.ticket?.road_cost || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Переводом:</Box>
                      <Box className="text">
                        {ticket?.ticket?.transfer || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Тип:</Box>
                      <Box className="text">
                        {ticket?.ticket?.type || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Рабочие часы:</Box>
                      <Box className="text">
                        {ticket?.ticket?.work_hours || '---'}
                      </Box>
                    </Box>
                    {/* Информация о менеджере */}
                    <Box
                      className={'label'}
                      style={{
                        fontSize: '23px',
                        margin: '15px 0'
                      }}
                    >
                      Информация о менеджере
                    </Box>

                    <Box className="wrap">
                      <Box className="label">ID менеджера:</Box>
                      <Box className="text">{ticket?.manager?.id || '---'}</Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Почта менеджера:</Box>
                      <Box className="text">
                        {ticket?.manager?.email || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Менеджер:</Box>
                      <Box className="text">
                        {ticket?.manager?.username || '---'}
                      </Box>
                    </Box>
                    {/* Информация о мастере */}
                    <Box
                      className={'label'}
                      style={{
                        fontSize: '23px',
                        margin: '15px 0'
                      }}
                    >
                      Информация о мастере
                    </Box>

                    <Box className="wrap">
                      <Box className="label">ID мастера:</Box>
                      <Box className="text">{ticket?.master?.id || '---'}</Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Почта мастера:</Box>
                      <Box className="text">
                        {ticket?.master?.email || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">ФИО мастера:</Box>
                      <Box className="text">{ticket?.master?.fio || '---'}</Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Мастер:</Box>
                      <Box className="text">
                        {ticket?.master?.username || '---'}
                      </Box>
                    </Box>
                    {/* Общая информация */}
                    <Box
                      className={'label'}
                      style={{
                        fontSize: '23px',
                        margin: '15px 0'
                      }}
                    >
                      Общая информация
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Дата завершения:</Box>
                      <Box className="text">
                        {ticket?.completeInfo?.date_completed !== undefined &&
                        ticket?.completeInfo?.date_completed !== null
                          ? `${ticket?.completeInfo?.date_completed.slice(
                              0,
                              10
                            )} ${ticket?.completeInfo?.date_completed.slice(
                              11,
                              -5
                            )}`
                          : '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Доход:</Box>
                      <Box className="text">
                        {ticket?.completeInfo?.income || '---'}
                      </Box>
                    </Box>

                    <Box className="wrap">
                      <Box className="label">Комментарий:</Box>
                      <Box className="text">
                        {ticket?.completeInfo?.comment || '---'}
                      </Box>
                    </Box>

                    <Box
                      className="wrap"
                      style={{
                        display: 'block'
                      }}
                    >
                      <Box className="label">Вложения:</Box>
                      <Box
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          marginTop: '15px'
                        }}
                      >
                        {imgTicket !== undefined &&
                        imgTicket !== null &&
                        imgTicket !== 'undefined' ? (
                          <Box
                            style={{
                              maxWidth: '100%',
                              margin: '0 auto',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 20
                            }}
                          >
                            {imgTicket.map((el) => (
                              <a
                                href={`${process.env.REACT_APP_API_URL}/public/uploads/images/${el}`}
                                target={'_blank'}
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/public/uploads/images/${el}`}
                                  style={{
                                    maxWidth: '300px',
                                    maxHeight: '500px'
                                  }}
                                />
                              </a>
                            ))}
                          </Box>
                        ) : (
                          <Avatar
                            style={{
                              width: 'max-content',
                              height: 'max-content',
                              borderRadius: '0'
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default MasterCompletedTicket;
