import React, { useState, useEffect, } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const TicketCategoryList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const deleteR = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [TicketCategory, setTicketCategory] = useState([]);
  const [sorteredTicketCategory, setSorteredTicketCategory] = useState([...TicketCategory]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  let [ actionDelete, setActionDelete ] = useState(0);

  const [queryParams, setQueryParams] = useState({
    id: searchParams.get('id') || '',
    category_name: searchParams.get('category_name') || '',
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    let params = [];
    if (queryParams.category_name !== '') {
      TicketCategory.map(el =>
        el.category_name.toLowerCase().includes(queryParams.category_name.toLowerCase())
        ? params.push(el)
        : ''
      )
    } else params = [...TicketCategory];

    if (queryParams.id !== '') {
      params = params.filter(el => el.id === Number(queryParams.id));
    }

    setSorteredTicketCategory(params)
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить этот вид работы?',
      onConfirm: () => {
        deleteR(`ticket_category/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadPayments();
            }
          })
          .catch((e) => {
            console.log(e.response);
          })
          .finally(()=>{
            setActionDelete(actionDelete = actionDelete+1)
          });
      }
    });
  };

  const loadPayments = () => {
    setIsDataLoading(true);

    let endpoint = `ticket_category?page=${page + 1}&limit=${limit}`;

    let newData = [...TicketCategory];

    if (queryParams.category_name !== '') {
      endpoint += `&category_name=${searchParams.get('category_name')}`;
      newData = newData.filter((el) => el.category_name === queryParams.category_name);
    }
    if (queryParams.id !== '') {
      endpoint += `&id=${searchParams.get('id')}`;
      newData = newData.filter((el) => el.id === queryParams.id);
    }

    setTicketCategory(newData);
    setIsDataLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`ticket_category`)
      .then((resp) => {
        if (resp.status === 'success') {
          setTicketCategory(resp.data.category);
          setValues({ ...values, role: resp.data.category[1] });
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке видов работ, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
    if (queryParams.category_name === '') {
      searchParams.delete('category_name');
      setSearchParams(searchParams);
    }
    if (queryParams.id === '') {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [actionDelete]);

  useEffect(() => {
    loadPayments();
  }, [page, limit, actionDelete]);

  useEffect(() => {
    setSorteredTicketCategory([...TicketCategory]);
  }, [TicketCategory]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Виды работ</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Виды работ </Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="./add">
                    <Button color="primary" variant="contained">
                      Добавить вид работы
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <div className="regions__filter">
                          <TextField
                            fullWidth
                            label="Id"
                            margin="normal"
                            name="id"
                            onChange={handleChangeQueryParams}
                            type="text"
                            value={queryParams.id}
                            variant="outlined"
                            style={{ width: '30%' }}
                          />
                          <TextField
                            fullWidth
                            label="Сategory name"
                            margin="normal"
                            name="category_name"
                            onChange={handleChangeQueryParams}
                            type="text"
                            value={queryParams.category_name}
                            variant="outlined"
                            style={{ width: '55%' }}
                          />
                        </div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>ID</TableCell>
                            <TableCell>Вид работы</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                          {sorteredTicketCategory?.map((ticket) => (
                            <TableRow hover key={ticket.id}>
                              <TableCell sx={{ width: 80 }}>
                                {ticket.id}
                              </TableCell>
                              <TableCell>{ticket.category_name || '---'}</TableCell>
                              <TableCell style={{ display: 'flex', alignItems:'center' }}>
                                <Box sx={{ marginLeft:'75%', cursor:'pointer' }}>
                                    <Link
                                      to={`/app/ticketCategory/edit/${ticket.id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                      >
                                        Редакт.
                                      </Button>
                                    </Link>
                                  </Box>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box style={{ marginLeft: '25%' }}>
                                      <Button
                                        color="error"
                                        variant="contained"
                                        onClick={() => onDelete(ticket.id)}
                                      >
                                        Удалить
                                      </Button>
                                    </Box>
                                  </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={TicketCategory.length}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default TicketCategoryList;