import { ListItem, Checkbox, List, Box } from '@mui/material';
import Alert from '@material-ui/core/Alert';
import styles from './instruction.module.css';
import { Button } from '@material-ui/core';
const InstructionMenu = ({
  putSection,
  section,
  setSection,
  alert,
  recipient,
  containers,
  setContainers,
  selectedItems,
  setSelectedItems,
  copiedContainers,
  setCopiedContainers
}) => {
  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id
    );
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
      console.log(selectedItems);
      setCopiedContainers(
        copiedContainers.filter((container) => container.id !== item.id)
      );
    } else {
      const updatedSelectedItems = [...selectedItems, item];
      setSelectedItems(updatedSelectedItems);
      setCopiedContainers([...copiedContainers, item]);
    }
  };
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Разделы</h3>
      {alert.txt === 'Добавьте разделы' && (
        <Alert
          severity={alert.type}
          style={{ display: alert.isVisible ? 'flex' : 'none' }}
        >
          {alert.txt}
        </Alert>
      )}
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          maxHeight: 300,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll'
        }}
      >
        {section.map((item) => (
          <ListItem key={item.id}>
            <Checkbox
              checked={
                copiedContainers.some(
                  (container) => container.id === item.id
                ) || false
              }
              onChange={(e) => handleCheckboxChange(item)}
            />
            <span>{item.name}</span>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          marginTop: 2
        }}
      >
        <Button
          onClick={() => putSection()}
          sx={{
            height: '40px'
          }}
          variant="contained"
        >
          Сохранить
        </Button>
      </Box>
    </div>
  );
};
export default InstructionMenu;
