import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';

const ManagerInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    setIsLoaded(true);

    getU(`admin/users/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setUser(resp.data.user);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      })
  }, []);

  // const formateDate = (inputDate) => {
  //   const date = new Date(inputDate);
  //   const day = String(date.getUTCDate()).padStart(2, "0");
  //   const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  //   const year = date.getUTCFullYear();
  //   return `${day}.${month}.${year}`;    
  // }

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {'Менеджеры'}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>
            {'Менеджеры'}
          </li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">

                  <div className="wrapAvatar__block">
                    <div className="avatar-block">
                      <Avatar
                        src={`${process.env.REACT_APP_API_URL}/public/uploads/avatars/${user.avatar}`}
                        className="avatar"
                      />
                    </div>
                    <div className="info-block">
                      <div className="wrap">
                        <div className="label">Идентификатор:</div>
                        <div className="text">{user?.id || '---'}</div>
                      </div>
        
                      <div className="wrap">
                        <div className="label" onClick={() => console.log(user)}>Имя пользователя:</div>
                        <div className="text">{user?.username || '---'}</div>
                      </div>
                      
                      <div className="wrap">
                        <div className="label">Электронная почта:</div>
                        <div className="text">{user?.email || '---'}</div>
                      </div>
        
                      <div className="wrap">
                        <div className="label">Роль:</div>
                        <div className="text">{user?.role || '---'}</div>
                      </div>
                    </div>
                  </div>                
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ManagerInfo;
