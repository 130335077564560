import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  CardContent,
  Card,
  CardHeader,
  Divider,
  Alert,
  Stack
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost } from '../../API/request';
//import Calendar from 'react-calendar';
import moment, { months } from 'moment';
import './Clendar.css';
import GraphBlock from '../../components/GraphBlock';
import { logging } from '../../API/logging';

const Schedule = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [requests, setRequests] = useState([]);
  const [disabledHours, setDisabledHours] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const getU = useGet();
  const postU = usePost();
  const [dateNow, setDateNow] = useState({
    year: new Date().getFullYear(),
    month:
      String(new Date().getMonth() + 1).length > 1
        ? new Date().getMonth() + 1
        : '0' + (new Date().getMonth() + 1),
    day: '01'
  });
  const [userName, setUserName] = useState('');
  const [days, setDays] = useState([]);
  /*const [selectedDays, setSelectedDays] = useState([]);
  const nonworkingDay = useMemo(() => {
    const setNonworkingDay = [];
    days.map((el) => {
      if (el.day === 0) setNonworkingDay.push(el.date);
    });
    return setNonworkingDay;
  }, [days]);*/

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const getCalendarData = async () => {
    const calendarRequest = await getU(
      `nonworkingtime/work_month/${id}?month=${moment(new Date()).format(
        'YYYY-MM-DD'
      )}`
    );

    if (calendarRequest.status === 'success') {
      const calendarData = calendarRequest.data.data;

      const disabledHours = [];

      calendarData.map((blocks) => {
        blocks.map((block) => {
          block.map((day) => {
            day.hours.map((hourData) => {
              if (!hourData.isWork) disabledHours.push(hourData.time);
            });
          });
        });
      });

      setData(calendarData);
      setDisabledHours(disabledHours);
    }
  };

  useEffect(() => {
    if (requests.length === 0) setSubmitDisabled(true);
    else setSubmitDisabled(false);
  }, [requests]);

  useEffect(() => {
    getCalendarData();
  }, []);

  useEffect(async () => {
    const resp = await getU(`admin/users/${id}`);
    if (resp.status === 'success') {
      setUserName(resp.data.user.username);
    }

    logging({
      type: 'admin schedule graph',
      message: 'admin schedule graph',
      additionalContext: {
        data: data
      }
    });
  }, []);

  useEffect(() => {
    getU(
      `nonworkingtime/day/${id}?month=${dateNow.year}-${dateNow.month}-${dateNow.day}`
    ).then((resp) => {
      const getData = resp.data.data.calendar;
      if (getData.length > 0) {
        getData.map((el) => {
          const modifDate = new Date(el.date);
          el.date = moment(modifDate).format('YYYY-MM-DD');
          return el;
        });
      }

      setDays(getData);
    });
  }, [dateNow]);

  /*const clickDayHandler = (date) => {
    const formatDate = moment(date).format('YYYY-MM-DD');
    const newDays = [...days];
    const dayIndex = newDays.findIndex((el) => el.date === formatDate);
    newDays[dayIndex].day === 0
      ? (newDays[dayIndex].day = 1)
      : (newDays[dayIndex].day = 0);
    setSelectedDays([...selectedDays, formatDate]);
    setDays(newDays);
  };*/

  /*const submit = () => {
    setSubmitDisabled(true);
    let counter = selectedDays.length;
    selectedDays.map((day) => {
      const data = {
        master_id: Number(id),
        date: day
      };
      console.log(data);
      postU('nonworkingtime/day', data)
        .then((resp) => {
          if (resp.status === 'success') {
            counter--;
          } else {
            showAlert('error', `Ошибка: ${resp.message}`);
          }
        })
        .catch((err) => {
          showAlert('error', `Ошибка сервера: ${err.message}`);
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    });
    setTimeout(() => {
      if (counter === 0) {
        showAlert('success', 'Данные успешно обновленны');
      }
    }, 1234);
  };*/

  const submitSaveButton = () => {
    let counter = requests.length;

    setSubmitDisabled(true);

    requests.map((request) => {
      if (request.toDo === 'triggerHour')
        postU('nonworkingtime/hours', {
          master_id: id,
          date: moment.utc(request?.data).format('YYYY-MM-DD'),
          time: moment(request?.data)
            .zone(-180 - new Date().getTimezoneOffset())
            .format('HH:mm:ss'),
          duration: 1
        })
          .then((resp) => {
            if (resp.status === 'success') counter--;
            else showAlert('error', `Ошибка сервера: ${err.message}`);
          })
          .catch((err) => {
            showAlert('error', `Ошибка сервера: ${err.message}`);
          });
      else
        postU('nonworkingtime/day', {
          date: moment.utc(request?.data).format('YYYY-MM-DD'),
          master_id: id
        })
          .then((resp) => {
            if (resp.status === 'success') counter--;
            else showAlert('error', `Ошибка: ${resp.message}`);
          })
          .catch((err) => {
            showAlert('error', `Ошибка сервера: ${err.message}`);
          });
    });

    setTimeout(() => {
      if (counter === 0) showAlert('success', 'Данные успешно обновленны');
    }, 1234);

    setRequests([]);
  };

  return (
    <>
      <Helmet>
        <title>Edit schedule</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Мастер {userName}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Редактирование {userName}</li>
          <li>/</li>
          <li>Редактирование графика</li>
        </ul>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          mb: 5
        }}
      >
        <Alert
          severity={alert.type}
          style={{ display: alert.isVisible ? 'flex' : 'none' }}
        >
          {alert.txt}
        </Alert>
        <Container
          maxWidth={false}
          style={{ alignContent: 'center', display: 'flex' }}
        >
          <Box sx={{ mb: 1, mt: 1 }}></Box>
          <Box sx={{ pt: 2 }}>
            <Card>
              <CardHeader title="Настройка графика мастера" />
              <Divider />

              {data.map((blocks, index) => {
                return (
                  <CardContent
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                      }}
                    >
                      {blocks.map((block) => {
                        return (
                          <div className="table" style={{ padding: 5 }}>
                            <GraphBlock
                              block={block}
                              disabledHours={disabledHours}
                              setDisabledHours={setDisabledHours}
                              requests={requests}
                              setRequests={setRequests}
                            />
                          </div>
                        );
                      })}
                    </Box>
                    {index === data.length - 1 && (
                      <Button
                        sx={{ mt: 3, width: 250 }}
                        color="primary"
                        variant="contained"
                        onClick={submitSaveButton}
                        disabled={submitDisabled ? true : false}
                        style={{
                          marginLeft:
                            data.length === 3 ? window.innerWidth / 3.3 : 0
                        }}
                      >
                        Сохранить
                      </Button>
                    )}
                  </CardContent>
                );
              })}
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Schedule;
