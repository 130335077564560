import React, { useState, useLayoutEffect, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Alert,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDelete, useGet, usePost, usePut } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import PaymentItem from '../../components/Payment/PaimentItem';
import MasterPayment from '../../components/masterPayment/masterPayment';
import { useSelector } from 'react-redux';
import { MasterAttachedFiles } from './MasterAttachedFiles';


const MasterInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();
  const postU = usePost();
  const { auth } = useSelector((state) => state);

  const { id } = useParams();
  const [masterStatistic, setMasterStatistic] = useState({
    avSumAllOrders: null,
    avSumOrders: null,
    canClientOrder: null,
    canOrder: null,
    lOrders: null,
    nOrders: 0,
    offDay: 31,
    offHours: 0,
    s_avSumAllOrders: 'Хорошо',
    s_avSumOrders: 'Хорошо',
    s_canClientOrder: 'Хорошо',
    s_canOrder: 'Хорошо',
    s_lOrders: 'Хорошо'
  });
  const outside = useSelector((state) => state.typeMaster.type);
  const [completedTicket, setCompletedTicket] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isSubmitAddPayment, setIsSubmitAddPayment] = useState(false);
  const [payments, setPayments] = useState([]);
  const [pagesPayments, setPagesPayments] = useState([]);
  const [user, setUser] = useState({});
  const [days, setDays] = useState(10);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sumValue, setSumValue] = useState('');
  const [comment, setComment] = useState('');
  const [filePayment, setFilePayment] = useState('');
  const [masterInfo, setMasterInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [statisticParam, setStatisticParam] = useState({
    id: null,
    normal: null,
    good: null
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const valueForSelectStatisticParam = useMemo(() => {
    if (statisticParam.id === 1) return 'Подъемные заказы';
    else if (statisticParam.id === 2) return 'Средняя сумма подъема';
    else if (statisticParam.id === 3) return 'Средний подъем на каждый заказ';
    else if (statisticParam.id === 4) return 'Заказы отмененные клиентом';
    else if (statisticParam.id === 5) return 'Сорванные заказы';
  }, [statisticParam]);

  const [alert, setAlert] = useState({
    txt: '',
    isFilesSend: false,
    isStatisticParamSend: false,
    type: 'error'
  });

  const loadPayments = () => {
    getU(`payment/${id}?days=${days}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setPayments(resp.data.Payment);
          setPagesPayments(resp.data.pages);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const loadFreelance = () => {
    setIsLoaded(true);

    getU(`admin/masters/freelance/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          // console.log(resp, 'resp users');
          // const user = resp.data.users.find((el) => el.id === Number(id));
          // console.log(user, 'user');

          if (resp.data.master.param.beginSchedule !== null)
            resp.data.master.beginSchedule = formateDate(
              resp.data.master.beginSchedule
            );
          if (resp.data.master.visible !== null)
            resp.data.master.param.visible === 1
              ? (resp.data.master.param.visible = true)
              : (resp.data.master.param.visible = false);
          console.log(resp.data.master, 'user');
          setUser(resp.data.master);
        }
        console.log(user);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };



  const loadMaster = () => {
    setIsLoaded(true);
 
    getU(`admin/masters/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          // console.log(resp, 'resp users');
          // const user = resp.data.users.find((el) => el.id === Number(id));
          // console.log(user, 'user');

          if (resp.data.master.param.beginSchedule !== null)
            resp.data.master.beginSchedule = formateDate(
              resp.data.master.beginSchedule
            );
          if (resp.data.master.visible !== null)
            resp.data.master.param.visible === 1
              ? (resp.data.master.param.visible = true)
              : (resp.data.master.param.visible = false);
          console.log(resp.data.master, 'user');
          setUser(resp.data.master);
        }
        console.log(user);
      })
      .catch((err) => {
        loadFreelance()
        console.log(err);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleStatisticParam = (key, value) => {
    setStatisticParam((prev) => ({
      ...prev,
      [key]: Number(value)
    }));
  };

  const putStatisticParam = () => {
    const data = {
      normal: statisticParam.normal,
      good: statisticParam.good
    };

    putU(`statistic_param/${statisticParam.id}`, data)
      .then((resp) => {
        setAlert((prev) => ({
          ...prev,
          txt:
            resp.message !== null ? resp.message : 'Данные успешно обновленны',
          isStatisticParamSend: true,
          type: resp.status
        }));
      })
      .catch((err) => {
        setAlert((prev) => ({
          ...prev,
          txt: err.message,
          isStatisticParamSend: true,
          type: 'error'
        }));
      })
      .finally(() => {
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            isStatisticParamSend: false
          }));
        }, 1000);
      });
  };

  const formateDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };


  const handleChangeDays = () => {
    const newDays = days + 10; // Новое значение дней

    const nextTrueDaysIndex = pagesPayments.findIndex(
      (item) => item.days === newDays && item.status === true
    );
    if (nextTrueDaysIndex !== -1) {
      // Если найдено значение true для новых дней, устанавливаем новые дни
      setDays(newDays);
    } else {
      // Если не найдено, ищем ближайшее значение true
      const nearestTrueDays = pagesPayments.find(
        (item) => item.status === true && item.days > newDays
      );
      if (nearestTrueDays) {
        // Если найдено ближайшее значение true, устанавливаем дни на это значение
        setDays(nearestTrueDays.days);
      } else {
        // Если не найдено ни одного значения true, выводим ошибку
        setAlert({
          txt: 'Не найдено платежей за указанный отрезок времени',
          type: 'error',
          isFilesSend: true
        });
        setTimeout(() => {
          setAlert({
            txt: 'Не найдено платежей за указанный отрезок времени',
            type: 'error',
            isFilesSend: false
          });
        }, 4000);
      }
    }
  };



  const toggleVisibleMaster = () => {
    putU(`admin/users/param/${id}`, { visible: !user.param.visible });

    setUser((user) => ({
      ...user,
      param: {
        ...user.param,
        visible: !user.param.visible
      }
    }));
  };
  const getMasterInfo = () => {
    getU(`admin/master/balance/${id}`)
      .then((res) => {
        if (res.status === 'success') {
          console.log(res.data);
          setMasterInfo(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMasterInfo();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  //-----------------------------------------------------------------------------добавить платеж
  const handleAddInfoMaster = async (e) => {
    e.preventDefault();
    if (!sumValue) {
      setAlert({
        txt: 'Поле сумма обязательна к заполнению',
        type: 'error',
        isFilesSend: true
      });
      setTimeout(() => {
        setAlert({
          txt: 'Поле сумма обязательна к заполнению',
          type: 'error',
          isFilesSend: false
        });
      }, 2000);
      return;
    }

    if (!comment) {
      setAlert({
        txt: 'Поле комментарий обязательно к заполнению',
        type: 'error',
        isFilesSend: true
      });
      setTimeout(() => {
        setAlert({
          txt: 'Поле комментарий обязательно к заполнению',
          type: 'error',
          isFilesSend: false
        });
      }, 2000);
      return;
    }
    setIsSubmitAddPayment(true);

    const formData = new FormData();
    formData.append('user_id', id);
    formData.append('payment', sumValue);
    formData.append('comment', comment);
    formData.append('img', filePayment[0]);


    await postU('admin/master/payment', formData)
      .then((res) => {
        if (res.status === 'success') {
          setSumValue('');
          setComment('');
          setFilePayment('');
          setAlert({
            txt: 'Успешно добавлено',
            isFilesSend: true,
            type: 'success'
          });
          setTimeout(() => {
            setModalOpen(false);
            setAlert({
              txt: 'Успешно добавлено',
              isFilesSend: false,
              type: 'success'
            });
          }, 4000);
        } else {
          throw new Error(res.message)
        }
      })
      .catch((error) => {
        setAlert({
          txt: error.message,
          isFilesSend: true,
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
          setAlert((prev) => ({
            ...prev,
            isFilesSend: false
          }));
        }, 2000);
        loadPayments();
        setIsSubmitAddPayment(false);
      });
  };

  useLayoutEffect(() => {
    setIsLoaded(true);

    loadMaster();

    getU(`statistic/general/${id}`).then((res) => {
      if ((res.status = 'success')) {
        console.log(res.data);
        setMasterStatistic({
          avSumAllOrders: res.data.avSumAllOrders,
          avSumOrders: res.data.avSumOrders,
          canClientOrder: res.data.canClientOrder,
          canOrder: res.data.canOrder,
          lOrders: res.data.lOrders,
          nOrders: res.data.nOrders,
          offDay: res.data.offDay,
          offHours: res.data.offHours,
          s_avSumAllOrders: res.data.s_avSumAllOrders,
          s_avSumOrders: res.data.s_avSumOrders,
          s_canClientOrder: res.data.s_canClientOrder,
          s_canOrder: res.data.s_canOrder,
          s_lOrders: res.data.s_lOrders
        });
      }
    });

    getU(`completed_ticket?master_id=${id}&limit=${limit}&page=${page}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCompletedTicket(resp.data.ticket);
          setTotalCount(resp.data.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoaded(false);
        setIsModalVisible(true);
      });
  }, [page, limit]);
  console.log(user);
  useEffect(() => {
    loadPayments();
  }, [days]);


  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }



  return (
    <>
      <Helmet>
        <title>User</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {'Мастера'}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>
            {'Мастера'}
          </li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Dialog
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          >
            <DialogContent
              sx={{
                width: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Box>
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isFilesSend ? 'flex' : 'none' }}
                >
                  {alert.txt}
                </Alert>
              </Box>
              <DialogTitle>Добавление информации</DialogTitle>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '15px'
                }}
              >
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="sum"
                  name="sum"
                  label="Введите сумму"
                  type="number"
                  placeholder="Сумма"
                  fullWidth
                  variant="outlined"
                  value={sumValue}
                  onChange={(e) => setSumValue(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  required
                  id="comment"
                  name="comment"
                  label="Введите комментарий"
                  type="text"
                  placeholder="Комментарий"
                  fullWidth
                  variant="outlined"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <input
                  name="file"
                  type="file"
                  id="input_payment"
                  className="input input__file"
                  onChange={(e) => setFilePayment(e.target.files)}
                  multiple
                />
                <Button
                  color="primary"
                  variant="contained"
                  style={{ cursor: 'pointer' }}
                >
                  <label for="input_payment" style={{ cursor: 'pointer' }}>
                    Приложить новый файл
                  </label>
                </Button>
                <span
                  style={{
                    color: 'gray',
                    fontSize: '12px'
                  }}
                >
                  *png *jpg *jpeg
                </span>
                <Button disabled={isSubmitAddPayment} onClick={handleAddInfoMaster} variant="outlined">
                  Добавить
                </Button>
              </FormControl>
            </DialogContent>
          </Dialog>


          <Card>

            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="wrapAvatar__block">
                    <div className="avatar-block">
                      <Avatar
                        src={`${process.env.REACT_APP_API_URL}/public/uploads/avatars/${user.avatar}`}
                        className="avatar"
                      />
                    </div>
                    <div className="info-block">
                      <div className="wrap">
                        <div className="label">Идентификатор:</div>
                        <div className="text">{user?.id || '---'}</div>
                      </div>

                      <div className="wrap">
                        <div
                          className="label"
                          onClick={() => console.log(user)}
                        >
                          Имя пользователя:
                        </div>
                        <div className="text">{user?.username || '---'}</div>
                      </div>

                      <div className="wrap">
                        <div
                          className="label"
                          onClick={() => console.log(user)}
                        >
                          ФИО:
                        </div>
                        <div className="text">{user.param?.fio || '---'}</div>
                      </div>

                      <div className="wrap">
                        <div className="label">Роль:</div>
                        <div className="text">{user?.role || '---'}</div>
                      </div>
                      <div className="wrap">
                        <div className="label">Вид мастера:</div>
                        <div className="text">
                        {/* {user.role} */}
                        {user.role && user.role === 'freelance_master' 
                        ? 
                        'Мастер не штатный'
                        :
                        'Мастер штатный'
                        }
                          {/* {
                            user.role === 'regional_manager'
                            ? user.role
                            : user.param?.full_time === 0
                            ? 'Мастер не штатный'
                            : 'Мастер штатный' || '---'
                            } */}
                        </div>
                      </div>
                    </div>
                    <div className="info-block-details">
                      <div className="wrap">
                        <p className="label">Выручка: </p>
                        <span className="text">{masterInfo.balance}</span>
                      </div>
                      <div className="wrap">
                        <p className="label">Текущий заработок: </p>
                        <span className="text">{masterInfo.curBalance}</span>
                      </div>
                      <div className="wrap">
                        <p className="label">Компенсация: </p>
                        <span className="text">{masterInfo.compensation}</span>
                      </div>
                      <div className="wrap">
                        <p className="label">За дорогу: </p>
                        <span className="text">
                          {masterInfo.fuelCompensation}
                        </span>
                      </div>
                      <div className="wrap">
                        <p className="label">Долг: </p>
                        <span className="text">{masterInfo.debt}</span>
                      </div>
                    </div>
                  </div>

                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">Отображение:</div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={user.param?.visible}
                          onChange={toggleVisibleMaster}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>

                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">Номер телефона:</div>
                      <div className="text">{user.param?.phone || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Telegram:</div>
                      <div className="text">
                        {user.param?.Telegram || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Whats-app:</div>
                      <div className="text">
                        {user.param?.WhatsApp || '---'}
                      </div>
                    </div>
                    {/* <div className="wrap">
                      <div className="label">Процент со 100% гарантии:</div>
                      <div className="text">
                        {`${user.param?.guarantee_compensation}%` || '---'}
                      </div>
                    </div> */}

                    <div className="wrap">
                      <div className="label">Общая компенсация:</div>
                      <div className="text">
                        {user.param?.generalCompensation || '---'}
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="label">Процент:</div>
                      <div className="text">{user.param?.bet || '---'} %</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Процент с допродаж:</div>
                      <div className="text">
                        {user.param?.lift_compensation || '---'} %
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Города:</div>
                      <div className="text">{user.param?.cities || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Навыки:</div>
                      <div className="text">{user.param?.tags || '---'}</div>
                    </div>
                  </div>

                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">Рабочий график:</div>
                      <div className="text">
                        {user.param?.schedule || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Дата начала отсчета графика:</div>
                      <div className="text">
                        {user.param?.beginSchedule || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Время начала:</div>
                      <div className="text">
                        {user.param?.timeBegin || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Время окончания:</div>
                      <div className="text">{user.param?.timeEnd || '---'}</div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Box style={{ backgroundColor: 'background.default', marginTop: '15px' }}>
        <Container maxWidth={false}>
          <Box>
            <Alert
              severity={alert.type}
              style={{ display: alert.isFilesSend ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>

          <MasterAttachedFiles user={user} id={id} loadMaster={loadMaster} />

        </Container>
      </Box>
      <Container maxWidth={false} sx={{ py: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <PaymentItem
              title="Подъемные заказы"
              value={
                masterStatistic?.lOrders === null
                  ? '---'
                  : masterStatistic?.lOrders + '%'
              }
              marker={masterStatistic?.s_lOrders}
            />
          </Grid>
          <Grid item xs>
            <PaymentItem
              title="Средняя сумма подъема"
              value={
                masterStatistic?.avSumOrders === null
                  ? '---'
                  : masterStatistic?.avSumOrders
              }
              marker={masterStatistic?.s_avSumOrders}
            />
          </Grid>
          <Grid item xs>
            <PaymentItem
              title="Средний подъем на каждый заказ"
              value={
                masterStatistic?.avSumAllOrders === null
                  ? '---'
                  : masterStatistic?.avSumAllOrders
              }
              marker={masterStatistic?.s_avSumAllOrders}
            />
          </Grid>
          <Grid item xs>
            <PaymentItem
              title="Заказы, отмененные клиентом"
              value={
                masterStatistic?.canClientOrder === null
                  ? '---'
                  : masterStatistic?.canClientOrder + '%'
              }
              marker={masterStatistic?.s_canClientOrder}
            />
          </Grid>
          <Grid item xs>
            <PaymentItem
              title="Сорванные заказы"
              value={
                masterStatistic?.canOrder === null
                  ? '---'
                  : masterStatistic?.canOrder + '%'
              }
              marker={masterStatistic?.s_canOrder}
            />
          </Grid>
        </Grid>
      </Container>
      <Box style={{ backgroundColor: 'background.default' }}>
        <Container maxWidth={false}>
          <Box>
            <Alert
              severity={alert.type}
              style={{ display: alert.isStatisticParamSend ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Card>
            <div
              className="info-block"
              style={{ width: '100%', padding: '24px' }}
            >
              <div className="label">Указать порог оценки статистики:</div>
              <div>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormControl style={{ width: '35%' }} sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Категория</InputLabel>
                    <Select
                      labelId="status"
                      name="status"
                      value={valueForSelectStatisticParam}
                      label="Статус"
                      onChange={(e) =>
                        handleStatisticParam('id', e.target.value)
                      }
                    >
                      <MenuItem value={1}>Подъемные заказы</MenuItem>
                      <MenuItem value={2}>Средняя сумма подъема</MenuItem>
                      <MenuItem value={3}>
                        Средний подъем на каждый заказ
                      </MenuItem>
                      <MenuItem value={4}>Заказы отмененные клиентом</MenuItem>
                      <MenuItem value={5}>Сорванные заказы</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Значение: 'норма'"
                    margin="normal"
                    name="normal"
                    onChange={(e) =>
                      handleStatisticParam('normal', e.target.value)
                    }
                    type="number"
                    value={statisticParam.normal}
                    min={0}
                    variant="outlined"
                    style={{ width: '20%' }}
                  />
                  <TextField
                    fullWidth
                    label="Значение: 'хорошо'"
                    margin="normal"
                    name="good"
                    onChange={(e) =>
                      handleStatisticParam('good', e.target.value)
                    }
                    type="number"
                    value={statisticParam.good}
                    min={0}
                    variant="outlined"
                    style={{ width: '20%' }}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={putStatisticParam}
                    sx={{ mt: 2, mb: 1 }}
                  >
                    Отправить
                  </Button>
                </Box>
              </div>
            </div>
          </Card>
        </Container>
      </Box>
      {completedTicket.length > 0 && (
        <Container maxWidth={false} style={{ marginTop: '24px' }}>
          <Card>
            <Box sx={{ p: 3 }}>
              <div className="label">Выполненные заказы:</div>
              <Table>
                <TableHead>
                  <TableRow
                    style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <TableCell>ID</TableCell>
                    <TableCell>ID Тикета</TableCell>
                    <TableCell>Дата завершения</TableCell>
                    <TableCell>Доход</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completedTicket?.map((ticket) => (
                    <TableRow hover key={ticket.id}>
                      <TableCell>{ticket?.id || '---'}</TableCell>
                      <TableCell>{ticket?.prettier_id || '---'}</TableCell>
                      <TableCell>
                        {ticket?.date_completed.slice(0, 10) || '---'}
                      </TableCell>
                      <TableCell>{ticket?.income || '---'}</TableCell>
                      <TableCell>
                        <Link
                          to={`/app/master/${user.id}/completedTicket/${ticket?.id}`}
                        >
                          <Button color="primary" variant="contained">
                            Инфо.
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        labelRowsPerPage={
                          <span>Кол-во строк на странице:</span>
                        }
                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                        colSpan={7}
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeLimit}
                      />
                    </TableRow>
                  </TableFooter>
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Container>
      )}

      <Container maxWidth={false} sx={{ marginTop: '25px' }}>
        <Card>
          <Box sx={{ p: 3 }}>
            {auth.user.role !== 'executive_manager' && (
              <Button
                sx={{
                  marginBottom: 3
                }}
                onClick={() => setModalOpen(true)}
                variant="outlined"
              >
                Добавить
              </Button>
            )}
            <>
              <div className="label">Платежи:</div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 80 }}>ID</TableCell>
                    <TableCell>ID Платежа</TableCell>
                    <TableCell>Статус</TableCell>
                    <TableCell>Сумма</TableCell>
                    <TableCell>Дата</TableCell>
                  </TableRow>
                </TableHead>
                {payments.length > 0 && (
                  <TableBody>
                    {payments?.map((payment) => (
                      <MasterPayment key={payment.id} item={payment} />
                    ))}
                  </TableBody>
                )}
              </Table>
              {payments.length > 0 && (
                <>
                  <Button
                    sx={{
                      marginTop: '20px'
                    }}
                    variant="contained"
                    onClick={handleChangeDays}
                  >
                    Запросить платежей еще на 10 дней
                  </Button>
                  <Box>
                    <Alert
                      severity={alert.type}
                      style={{ display: alert.isFilesSend ? 'flex' : 'none' }}
                    >
                      {alert.txt}
                    </Alert>
                  </Box>
                </>
              )}
            </>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default MasterInfo;
