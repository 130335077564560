import React from 'react';
import {
  TableBody,
  Box,
  TableCell,
  Button,
  TableRow,
  TableFooter,
  TablePagination,
  Avatar
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setType } from '../../redux/slices/type-master';
const MastersTable = ({
  masters,
  role,
  totalCount,
  page,
  handleChangeLimit,
  handleChangePage,
  onDelete,
  limit,
  outside
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <TableBody>
        {masters?.map((user) => (
          <TableRow hover key={user.id}>
            <TableCell sx={{ width: 80 }}>{user?.id}</TableCell>
            <TableCell>
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Avatar
                  src={
                    user.avatar
                      ? `${process.env.REACT_APP_API_URL}/public/uploads/avatars/${user.avatar}`
                      : ''
                  }
                />
              </Box>
            </TableCell>

            <TableCell>{user?.username || '---'}</TableCell>
            <TableCell>{user?.param.fio || '---'}</TableCell>
            <TableCell>{user?.param.phone || '---'}</TableCell>
            <TableCell>
              {user?.param?.cities?.split(', ')[0].trim() || '---'}
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex' }}>
                  <Link
                    onClick={() => dispatch(setType(outside ? 'outside' : ''))}
                    to={`/app/master/${user.id}`}
                  >
                    <Button color="primary" variant="contained">
                      Инфо.
                    </Button>
                  </Link>
                  <Box sx={{ ml: 2 }}>
                    <Link
                      to={
                        outside
                          ? `/app/outside/edit/${user.id}`
                          : `/app/master/edit/${user.id}`
                      }
                      style={
                        user?.role === 'admin' ? { pointerEvents: 'none' } : {}
                      }
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={user?.role === 'admin'}
                      >
                        Редакт.
                      </Button>
                    </Link>
                  </Box>
                </Box>
                {role !== 'executive_manager' && (
                  <Box style={{ marginLeft: '20px' }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={(e) => e && onDelete(user.id)}
                    >
                      Удалить
                    </Button>
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            labelRowsPerPage={<span>Кол-во строк на странице:</span>}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            colSpan={7}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeLimit}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default MastersTable;
