import {
  Button,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { useState } from 'react';
const MasterPayment = ({ item }) => {
  const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
  return (
    <TableRow hover>
      <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
      <TableCell>{item?.paymentId || '---'}</TableCell>
      <TableCell>{item?.status || '---'}</TableCell>
      <TableCell>{item?.payment || '---'}</TableCell>
      <TableCell>{item?.date.slice(0, 10) || '---'}</TableCell>
      {item.comment && (
        <>
          <Button
            onClick={() => setModalDetailsOpen(true)}
            sx={{
              marginLeft: '20px'
            }}
            variant="contained"
          >
            Комментарий
          </Button>
          <Dialog
            open={modalDetailsOpen}
            onClose={() => setModalDetailsOpen(false)}
          >
            <DialogContent>
              <DialogTitle>{item.comment}</DialogTitle>
              {item.img !== null &&
                item.img !== undefined &&
                item.img !== '' &&
                item.img !== 'undefined' && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/public/uploads/images/${item.img}`}
                    style={{
                      maxWidth: '300px',
                      maxHeight: '500px'
                    }}
                  />
                )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </TableRow>
  );
};

export default MasterPayment;
