import { createSlice } from '@reduxjs/toolkit';

export const recipientSlice = createSlice({
  name: 'recipient',
  initialState: {
    recipientSelect: ''
  },
  reducers: {
    setRecipient: (state, action) => {
      state.recipientSelect = action.payload;
    }
  }
});

export const { setRecipient } = recipientSlice.actions;

export default recipientSlice.reducer;
