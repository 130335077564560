import { Box, Button, Container } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';


const GoBack = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ pt: 2 }}>
      <Container maxWidth={false}>
        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </Container>
    </Box>
  );
};

export  { GoBack };