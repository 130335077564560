import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../components/Instruction/instruction.module.css';
import { setRecipient } from '../../redux/slices/recipient';
import { useNavigate } from 'react-router';
const InstructionSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const handleSend = (value) => {
    dispatch(setRecipient(value));
    navigate('/app/instruction-hierarchy');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '20px',
        gap: '30px'
      }}
    >
      <h2 className={styles.title}>
        Выберите кому будете настраивать иерархию
      </h2>
      {user.role !== 'executive_manager' && (
        <Button onClick={() => handleSend('Менеджера')} variant="outlined">
          Менеджера
        </Button>
      )}
      <Button onClick={() => handleSend('Мастера штатные')} variant="outlined">
        Мастера штатные
      </Button>
      <Button
        onClick={() => handleSend('Мастера не штатные')}
        variant="outlined"
      >
        Мастера не штатные
      </Button>
    </Box>
  );
};

export default InstructionSelect;
