import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import ReactQuill from 'react-quill';
import { Quill } from 'react-quill/lib';
import '../../styles/All.css';
import './instruction.css';

const InstructionInfo = () => {
  const [embeddedNames, setEmbeddedNames] = useState('');
  const navigate = useNavigate();
  const getI = useGet();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);

  const [instruction, setInstruction] = useState({});

  const setClassNameForImgTag = () => {
    const allElements = document
      .querySelector('.content__wr')
      .querySelectorAll('img');

    console.log(allElements);
    allElements.forEach((elem) => {
      elem.setAttribute('class', 'image__from__ckeditor');
    });

    console.log(allElements);
  };

  const valueForSelect = useMemo(() => {
    if (instruction.recipient === 1) return 'Менеджеры';
    else if (instruction.recipient === 2) return 'Мастера штатные';
    else if (instruction.recipient === 3) return 'Мастера не штатные';
    else return '';
  }, [instruction.recipient]);

  useEffect(() => {
    setIsLoaded(true);
    getI(`instruction/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setInstruction(resp.data.Instruction);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
        setClassNameForImgTag();
      });
  }, []);
  console.log(instruction);
  useEffect(() => {
    let newEmbeddedNames = '';

    if (instruction?.length !== 0) {
      instruction?.instructions?.map((el, index) => {
        if (index === instruction?.instructions?.length - 1)
          newEmbeddedNames += el.title;
        else newEmbeddedNames += el.title + ', ';
      });

      setEmbeddedNames(newEmbeddedNames);
    }
  }, [instruction]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Инструкция</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Инструкции
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Инструкции</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Card style={{ maxWidth: window.innerWidth - 315 }}>
            <CardContent sx={{ p: 3 }}>
              {/*<PerfectScrollbar>*/}
              <div className="wrapAvatar">
                <div className="info-block">
                  <div className="editor__data__wrap">
                    <div className="label">ID:</div>
                    <div className="text">{instruction?.id || '---'}</div>
                  </div>

                  <div className="editor__data__wrap">
                    <div className="label">Название инструкции:</div>
                    <div className="text">{instruction?.title || '---'}</div>
                  </div>

                  <div className="editor__data__wrap">
                    <div className="label">Получатель:</div>
                    <div className="text">{valueForSelect}</div>
                  </div>

                  <div className="editor__data__wrap">
                    <div className="label">Дата создания:</div>
                    <div className="text">
                      {instruction?.date_create.slice(0, 10) || '---'}
                    </div>
                  </div>

                  <div className="editor__data__wrap">
                    <div className="label">Дата обновления:</div>
                    <div className="text">
                      {instruction?.date_update.slice(0, 10) || '---'}
                    </div>
                  </div>

                  <div className="editor__data__wrap">
                    {Array.isArray(instruction?.instructions) &&
                    instruction.instructions.length < 1 ? (
                      <div className="text">
                        <b>Названия приложенных инструкций: </b>---
                      </div>
                    ) : (
                      <div className="text">
                        {' '}
                        <b>Названия приложенных инструкций: </b>
                        <text className="text">{embeddedNames}</text>
                      </div>
                    )}
                  </div>

                  <div className="editor__data__wrap">
                    <div className="label">Содержание:</div>
                  </div>
                  {/* <ReactQuill
                      modules={{
                        toolbar: toolbarOptions,
                      }} 
                      theme="snow" 
                      style={{height: '100%', marginTop: '15px', }}
                      value={instruction.data}
                    /> */}
                  <div
                    className="content__wr"
                    dangerouslySetInnerHTML={{ __html: instruction.data }}
                  ></div>
                </div>
              </div>
              {/*</PerfectScrollbar>*/}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default InstructionInfo;
