import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import { useSelector } from 'react-redux';

const InstructionList = () => {
  const confirm = useConfirm();
  const getI = useGet();
  const deleteR = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [instruction, setInstruction] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { user } = useSelector((state) => state.auth);
  let [actionDelete, setActionDelete] = useState(0);
  const [queryParams, setQueryParams] = useState({
    id: searchParams.get('id') || '',
    title: searchParams.get('title') || '',
    recipient: searchParams.get('recipient') || ''
  });

  const valueForSelect = (recipient) => {
    if (recipient === 1) return 'Менеджеры';
    else if (recipient === 2) return 'Мастера штатные';
    else if (recipient === 3) return 'Мастера не штатные';
    else return '';
  };
  console.log(queryParams.title);
  const handleFilterQueryParams = async () => {
    let recipient = 0;
    if (queryParams.recipient === 'Менеджеры') recipient = 1;
    if (queryParams.recipient === 'Мастера штатные') recipient = 2;
    if (queryParams.recipient === 'Мастера не штатные') recipient = 3;
    const { data } = await getI(
      `instruction/?${queryParams.title && `title=${queryParams.title}`}${
        queryParams.id && `&id=${queryParams.id}`
      }${recipient !== 0 && `&recipient=${recipient}`}`
    );
    setInstruction(data.Instruction);
  };
  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить инструкцию?',
      onConfirm: () => {
        deleteR(`instruction/${id}`)
          .catch((e) => {
            console.log(e.response);
          })
          .finally(() => {
            setActionDelete((actionDelete = actionDelete + 1));
          });
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  console.log(limit);
  useEffect(() => {
    setIsLoaded(true);

    getI(`instruction?page=${page}&limit=${limit === 10 ? 999 : limit}`)
      .then((resp) => {
        if (resp.status === 'success' && user.role !== 'executive_manager') {
          setInstruction(resp.data.Instruction);
        } else if (user.role === 'executive_manager') {
          setInstruction(
            resp.data.Instruction.filter((item) => item.recipient !== 1)
          );
        }
      })
      .finally(() => {
        setIsLoaded(false);
      });
    if (queryParams.title === '') {
      searchParams.delete('title');
      setSearchParams(searchParams);
    }
    if (queryParams.id === '') {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [actionDelete, page, limit]);
  console.log(user.role);

  console.log(instruction);
  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Инструкции</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Инструкции</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          {isLoaded ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ marginLeft: 2 }}>
                  <Link to="./add">
                    <Button color="primary" variant="contained">
                      Добавить инструкцию
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box sx={{ pt: 3 }}>
                <Card>
                  <PerfectScrollbar>
                    <Box sx={{ minWidth: 1400 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                          mb: 1
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '45%',
                            height: '56px',
                            height: 'max-content',
                            justifyContent: 'space-between'
                          }}
                        >
                          <TextField
                            fullWidth
                            label="ID"
                            margin="normal"
                            name="id"
                            type="text"
                            onChange={(event) =>
                              setQueryParams((preventQueryParams) => ({
                                ...preventQueryParams,
                                id: event.target.value
                              }))
                            }
                            variant="outlined"
                            style={{ width: '10%' }}
                          />
                          <TextField
                            fullWidth
                            label="Title"
                            margin="normal"
                            name="title"
                            type="text"
                            onChange={(event) =>
                              setQueryParams((preventQueryParams) => ({
                                ...preventQueryParams,
                                title: event.target.value
                              }))
                            }
                            variant="outlined"
                            style={{ width: '45%' }}
                          />
                          <FormControl
                            fullWidth
                            sx={{ mt: 2, mb: 1 }}
                            style={{ width: '25%' }}
                          >
                            <InputLabel id="schedule">Получатель</InputLabel>
                            <Select
                              labelId="Schedule"
                              label="Получатель"
                              onChange={(event) =>
                                setQueryParams((preventQueryParams) => ({
                                  ...preventQueryParams,
                                  recipient: event.target.value
                                }))
                              }
                            >
                              {user.role !== 'executive_manager' && (
                                <MenuItem value={'Менеджеры'}>
                                  Менеджеры
                                </MenuItem>
                              )}
                              <MenuItem value={'Мастера штатные'}>
                                Мастера штатные
                              </MenuItem>
                              <MenuItem value={'Мастера не штатные'}>
                                Мастера не штатные
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleFilterQueryParams}
                          sx={{ mt: 2, mb: 1 }}
                        >
                          Применить
                        </Button>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead
                          style={{
                            borderBottom: '1px solid rgba(224, 224, 224, 1)'
                          }}
                        >
                          <TableRow>
                            <TableCell sx={{ width: 80 }}>ID</TableCell>
                            <TableCell>Название инструкции</TableCell>
                            <TableCell>Получатель</TableCell>
                            <TableCell>Дата создания</TableCell>
                            <TableCell>Дата обновления</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {instruction?.map((instruction) => (
                            <TableRow hover key={instruction.id}>
                              <TableCell sx={{ width: 80 }}>
                                {instruction.id}
                              </TableCell>
                              <TableCell>
                                {instruction.title || '---'}
                              </TableCell>
                              <TableCell>
                                {valueForSelect(instruction.recipient)}
                              </TableCell>
                              <TableCell>
                                {instruction.date_create.slice(0, 10)}
                              </TableCell>
                              <TableCell>
                                {instruction.date_update.slice(0, 10)}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Box
                                  sx={{ cursor: 'pointer', marginLeft: 'auto' }}
                                >
                                  <Link
                                    to={`/app/instruction/${instruction.id}`}
                                  >
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                </Box>

                                <Box
                                  sx={{
                                    cursor: 'pointer',
                                    marginLeft: '20px'
                                  }}
                                >
                                  <Link
                                    to={`/app/instruction/edit/${instruction.id}`}
                                  >
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                  <Box style={{ marginLeft: '20px' }}>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      onClick={() => onDelete(instruction.id)}
                                    >
                                      Удалить
                                    </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              labelRowsPerPage={
                                <span>Кол-во строк на странице:</span>
                              }
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={instruction.length}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default InstructionList;
