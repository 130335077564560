import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import DashboardNavbar from '../components/Nav/DashboardNavbar';
import DashboardSidebar from '../components/Nav/DashboardSidebar';
import { GlobalLoader } from '../components/GlobalLoader/index';
import { Confirm } from '../components/Confirm';
import { AlertProvider } from '../components/Alert';
import { SnackBar, SnackBarProvider } from './SnackBar/SnackBar';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column'
});

const DashboardLayout = () => {
  const { user } = useSelector((state) => state.auth);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  let userDataLength = Object.keys(user).length;

  const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: userDataLength !== 0 ? 64 : 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: userDataLength !== 0 ? 256 : 0
    }
  }));

  return (
    <DashboardLayoutRoot>
      <SnackbarProvider maxSnack={10}>
        <SnackBar />
        {userDataLength !== 0 && (
          <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
        )}
        {userDataLength !== 0 && (
          <DashboardSidebar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        )}
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent sx={{ position: 'relative' }}>
              <Confirm>
                <AlertProvider>
                  <GlobalLoader>
                    <Outlet />
                  </GlobalLoader>
                </AlertProvider>
              </Confirm>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </SnackbarProvider>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
