import {
  Box,
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl
} from '@mui/material';
import { ChevronLeft } from '@material-ui/icons';
import InstructionMenu from '../../components/Instruction/InstructionMenu';
import InstructionSections from '../../components/Instruction/InstructionSections';
import { useEffect, useState } from 'react';
import { useGet, usePost, usePut } from '../../API/request';
import { Alert } from '@material-ui/core';
import { BallTriangle } from 'react-loader-spinner';
import styles from '../../components/Instruction/instruction.module.css';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const InstructionHierarchy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [section, setSection] = useState([]);
  const [name, setName] = useState('');
  const postU = usePost();
  const putU = usePut();
  const getU = useGet();
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState(null);
  const [copiedContainers, setCopiedContainers] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    JSON.parse(localStorage.getItem('selected')) || []
  );
  const [containers, setContainers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sectionId, setIdSection] = useState(null);
  const recipientSelect = useSelector(
    (state) => state.recipient.recipientSelect
  );
  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });
  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 1400);
  };
  const sendSection = async (e) => {
    e.stopPropagation();
    if (!name) {
      showAlert('error', 'Имя раздела должно быть обязательно заполнено');
    }

    try {
      const { data } = await postU('section', {
        name: name,
        recipient: recipient,
        parent_section: null
      });
      setSection([...section, data.section]);
      setIsOpen(false);
      setName('');
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const [dataRes, instructionRes] = await Promise.all([
        getU(`section/redactor/${recipient}`),
        getU(`section/redactor/${recipient}`)
      ]);

      if (dataRes.data) {
        // setParentSection(dataRes.data.data[0].parent_section);
        setIdSection(dataRes.data.data[0].id);
        setContainers(dataRes.data.data);
        setCopiedContainers(dataRes.data.data);
        setLoader(false);
      }

      console.log(containers, 'aaaaa');
      if (instructionRes.data.Instructions) {
        setContainers((prev) => [...prev, ...instructionRes.data.Instructions]);
      }
      if (dataRes.error) throw new Error(dataRes.error);
    } catch (error) {
      console.error(error);
    }
  };
  const modifySections = async () => {
    try {
      const promises = section.map(async (item) => {
        const isSelected = selectedItems.some(
          (selectedItem) => selectedItem.id === item.id
        );
        const find = selectedItems.find((checkBox) => checkBox.id === item.id);
        const isInContainers = containers.some(
          (container) => container.id === item.id
        );
        console.log(isSelected, 'sel');
        console.log(find, 'find');
        if (isSelected && !isInContainers) {
          if (find) {
            await putU(`section/${item.id}`, {
              name: item.name,
              parent_section: sectionId
            });
            await fetchData();
          }
        } else if (!isSelected) {
          await putU(`section/${item.id}`, {
            name: item.name,
            parent_section: null
          });
          await fetchData();
        }
      });

      await Promise.all(promises);
      showAlert('success', 'Секции успешно изменены.');
      localStorage.setItem('selected', JSON.stringify(selectedItems));
    } catch (error) {
      showAlert('error', 'Возникла ошибка при изменении секций.');
    }
  };

  useEffect(() => {
    getU(`section?recipient=${recipient}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setSection(resp.data.section);
        }
      })
      .catch(() => {});
  }, [recipient]);
  useEffect(() => {
    console.log('copiedContainers:', copiedContainers);
    console.log('section:', section);

    if (!localStorage.getItem('selected')) {
      const commonElements = copiedContainers.filter((container) =>
        section.some((item) => item.id === container.id)
      );

      console.log('commonElements:', commonElements);

      if (commonElements.length > 0) {
        localStorage.setItem('selected', JSON.stringify(commonElements));
        setSelectedItems(commonElements);
      }
    }
  }, [copiedContainers, section]);
  console.log(selectedItems);
  useEffect(() => {
    if (recipientSelect === 'Менеджера') setRecipient(1);
    else if (recipientSelect === 'Мастера штатные') setRecipient(2);
    else if (recipientSelect === 'Мастера не штатные') setRecipient(3);
    if (!recipientSelect) navigate('/app/instruction-select');
  }, [recipientSelect]);

  if (loader) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }
  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Alert
        severity={alert.type}
        style={{
          display: alert.isVisible ? 'flex' : 'none',
          justifyContent: 'flex-end'
        }}
      >
        {alert.txt}
      </Alert>
      <Helmet>
        <title>Иерархия инструкций</title>
      </Helmet>

      <Box
        sx={{
          padding: '50px 30px',
          display: 'flex',
          width: '100%',
          gap: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          <Button
            sx={{
              width: '100px'
            }}
            startIcon={<ChevronLeft />}
            onClick={() => navigate(-1)}
          >
            Назад
          </Button>
          <h1 className={styles.title}>
            Вы настраиваете иерархию инструкций для {recipientSelect}
          </h1>
          <InstructionMenu
            putSection={modifySections}
            setSection={setSection}
            section={section}
            setLoader={setLoader}
            loader={loader}
            alert={alert}
            recipient={recipient}
            containers={containers}
            setContainers={setContainers}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            copiedContainers={copiedContainers}
            setCopiedContainers={setCopiedContainers}
          />
          <Button
            sx={{
              marginTop: '20px',
              width: '50%'
            }}
            onClick={() => setIsOpen(true)}
            variant="outlined"
          >
            Добавить раздел
          </Button>
        </Box>
        <InstructionSections
          recipient={recipient}
          setContainers={setContainers}
          containers={containers}
          setIdSection={setIdSection}
          setLoader={setLoader}
          idSection={sectionId}
          loader={loader}
          fetchData={fetchData}
        />
        <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
          <DialogTitle>Добавьте раздел</DialogTitle>

          <DialogContent
            sx={{
              padding: '50px',
              width: '400px'
            }}
          >
            <Alert
              severity={alert.type}
              style={{ display: alert.isVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
            <FormControl>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px'
                }}
              >
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="name"
                  label="Введите имя раздела"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <DialogActions>
                <Button onClick={sendSection} variant="outlined">
                  Добавить раздел
                </Button>
              </DialogActions>
            </FormControl>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default InstructionHierarchy;
