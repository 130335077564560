import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useEffect } from 'react';
import { useGet, usePut } from '../../API/request';
import { memo } from 'react';
import { useSnackbar } from 'notistack';

export function SnackBar({children}) {
    const getU = useGet()
    const putU = usePut()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [buffer, setBuffer] = useState([])
    // const [open, setOpen] = useState(-1);
    const [data, setData] = useState([])
    const [update, setUpdate] = useState(false)
    const [disableClose, setDisableClose] = useState(false)

    const handleClose = (i, id) => {
        // if (disableClose) return
        setDisableClose(true)
        closeSnackbar(i)
        putU(`admin_notifications/${id}`, {status: true})
            .then((res) => {
                console.log(res)
                setUpdate(prev => !prev)
            })
            .catch((e) => {
                console.log(e)
                setDisableClose(false)
            })
    };

    const Action = ({i, id}) => (
        <>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleClose(i, id)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
        </>
    );

    const changeBuffer = (Dat) => {
        // const newEl = data.filter(el => !buffer.find(elem => elem.id === el.id)?.id) 
        // const oldEl = buffer.filter(el => data.find(elem => elem.id === el.id)?.id)
        console.log(buffer, Dat)
        // buffer.forEach((el, i) => {
        //     if (!Dat.find(elem => elem.id === el.id)?.id){
        //         closeSnackbar(i)
        //         console.log(i)
        //     }
        // })

        Dat.forEach((el, i) => {
            if (!buffer.find(elem => elem.id === el.id)?.id){
                console.log(i)
                enqueueSnackbar(el.message, {
                    variant: 'default',
                    action: (key) => (
                        <>
                            <Action i={key} id={el.id} />
                        </>
                    ),
                    autoHideDuration: null,
                    anchorOrigin: {horizontal: 'right', vertical: 'top'}
                })
            }
        })
        setBuffer(Dat)
    }

    useEffect(() => {
        let interval
        getU('admin_notifications')
        .then((res) => {
            if (res.status === 'success') {
                if(!data.length) {
                    res.data.notifications.forEach((el, i) => {
                        if (i < 10) 
                            enqueueSnackbar(el.message, {
                                variant: 'default',
                                action: (key) => (
                                    <>
                                        <Action i={key} id={el.id} />
                                    </>
                                ),
                                autoHideDuration: null,
                                anchorOrigin: {horizontal: 'right', vertical: 'top'},
                                persist: true,
                            })
                    })

                    setData(res.data.notifications)
                    setBuffer(res.data.notifications.slice(0, 10))
                }
                else if (res.data.notifications.length !== data.length){
                    setData(res.data.notifications)
                    changeBuffer(res.data.notifications.slice(0, 10))
                    // const audio = new Audio('https://meloboom.com/ru/r-_vC9nI870e')
                    // audio.play()
                }
                setDisableClose(false)
                interval = setTimeout(() => 
                    setUpdate(prev => !prev)
                , 10000)
            }
        })
        .catch((e) => {
            console.log(e)
            clearTimeout(interval)
        })
        return () => clearTimeout(interval)
    }, [update])

    return (
    <>
        {children}
    </>
    );
}


