import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Alert,
  CardHeader,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { usePost, useGet, usePut } from '../../API/request';
//import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/All.css';
//import { Quill } from 'react-quill/lib';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiUrl } from '../../API/environment';
import { useSelector } from 'react-redux';

import './styles.css';

const Instruction = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [data, setData] = useState('');
  const [recipient, setRecipient] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const postI = usePost();
  const getI = useGet();

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [instructionsList, setInstructionsList] = useState([]);
  const [selectedInstructions, setSelectedInstructions] = useState([]);

  const [uploadUrl] = useState(apiUrl + 'api/worker/CKEditorUpload');

  /*Functionality that was used with Quill. Right now CKEditor in use instead*/
  /*const fontSizeArr = [
    '8pt',
    '9pt',
    '10pt',
    '12pt',
    '14pt',
    '16pt',
    '20pt',
    '24pt',
    '32pt',
    '42pt',
    '54pt',
    '68pt',
    '84pt',
    '98pt'
  ];

  var Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);

  var toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons

    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ size: fontSizeArr }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image'],

    ['clean'] // remove formatting button
  ];*/

  const valueForSelect = useMemo(() => {
    if (recipient === 1) return 'Менеджеры';
    else if (recipient === 2) return 'Мастера штатные';
    else if (recipient === 3) return 'Мастера не штатные';
    else return '';
  }, [recipient]);

  const handleRecipient = (event) => {
    if (event.target.value === 'Менеджеры') setRecipient(1);
    else if (event.target.value === 'Мастера штатные') setRecipient(2);
    else if (event.target.value === 'Мастера не штатные') setRecipient(3);
  };

  const clearForm = () => {
    setTitle('');
    setData('');
    setRecipient(null);
    setSelectedInstructions([]);
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const submit = async () => {
    const instruction = {
      title: title,
      data: data,
      recipient: recipient
    };

    if (instruction.title === '') {
      showAlert('error', 'Поле Название Инструкции не должно быть пустым');
      return;
    }
    if (instruction.recipient === null) {
      showAlert('error', 'Поле Получатель не должно быть пустым');
      return;
    }
    if (instruction.data === '') {
      showAlert('error', 'Поле Инструкции не должно быть пустым');
      return;
    }

    if (
      instruction.title !== '' &&
      instruction.recipient !== null &&
      instruction.data !== ''
    ) {
      postI('instruction', instruction)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Instruction added');
            clearForm();
          } else {
            showAlert('error', `Ошибка: ${resp.message}`);
          }
        })
        .catch((err) => {
          console.log(err);
          showAlert('error', `Ошибка сервера: ${err.message}`);
        })
        .finally(() => {});
    }
  };

  const checkNewInstrId = (newInstrId) => {
    if (selectedInstructions.length < 1) return false;
    else if (selectedInstructions.find((el) => el === newInstrId) === undefined)
      return false;
    else return true;
  };

  const setNewSelectedInstructions = (value) => {
    const newInstrId = value[value.length - 1];
    if (checkNewInstrId(newInstrId) === true) {
      setSelectedInstructions((prev) => prev.filter((el) => el !== newInstrId));
    } else {
      setSelectedInstructions((prev) => [...prev, newInstrId]);
    }
  };

  // const getInstructionsList = (recipient) => {
  //   getI(`instruction/sub?recipient=${recipient}`).then((resp) => {
  //     if (resp.status === 'success') {
  //       setInstructionsList(resp.data.Instruction);
  //     }
  //   });
  // };

  return (
    <>
      <Helmet>
        <title>Инструкции</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box className="headerWrapper">
        <Box
          className="headerTitle"
          sx={{
            width: '92%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box className="headerTitle">Инструкции</Box>

        </Box>
      </Box>
      <Box
        sx={{
          width: '90%',
          height: '85%',
          marginTop: '2%',
          marginLeft: '2%',
          backgroundColor: 'white',
          borderRadius: '5px',
          border: '1px solid #6b778c83'
        }}
      >
        <Alert
          severity={alert.type}
          style={{ display: alert.isVisible ? 'flex' : 'none' }}
        >
          {alert.txt}
        </Alert>

        <Container maxWidth={false} style={{ height: '70%' }}>
          <TextField
            fullWidth
            label="Название инструкции"
            margin="normal"
            type="text"
            variant="outlined"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="schedule">Получатель</InputLabel>
            <Select
              labelId="Schedule"
              label="Получатель"
              value={valueForSelect}
              onChange={handleRecipient}
            >
              {user.role !== 'executive_manager' && (
                <MenuItem value={'Менеджеры'}>Менеджеры</MenuItem>
              )}
              <MenuItem value={'Мастера штатные'}>Мастера штатные</MenuItem>
              <MenuItem value={'Мастера не штатные'}>
                Мастера не штатные
              </MenuItem>
            </Select>
          </FormControl>
          {instructionsList.length > 0 && recipient !== null && (
            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
              <InputLabel id="selectedInstructions">
                Приложенные инструкции
              </InputLabel>
              <Select
                labelId="selectedInstructions"
                name="selectedInstructions"
                value={instructionsList}
                label="Приложенные инструкции"
                multiple
                renderValue={() => selectedInstructions.join(', ')}
                onChange={(e) => setNewSelectedInstructions(e.target.value)}
              >
                {instructionsList?.map((instr, idx) => (
                  <MenuItem value={instr.id} key={idx}>
                    <Checkbox checked={checkNewInstrId(instr.id)} />
                    <ListItemText primary={instr.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {/*<ReactQuill
                    modules={{
                      toolbar: toolbarOptions,
                    }}  
                    theme="snow" 
                    value={data}
                    style={{height: '95%', marginTop: '15px', }}
                    onChange={event => setData(event.valueOf())}
                     
                  /> */}
          <CKEditor
            config={{
              ckfinder: {
                uploadUrl: uploadUrl
              }
            }}
            editor={ClassicEditor}
            onChange={(event, editor) => {
              setData(String(editor.getData()));
            }}
            data={data}
          />
        </Container>
        <Box sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 20px"
        }}>
          <Button onClick={submit} color="primary" variant="contained">
            Отправить инструкцию
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Instruction;
