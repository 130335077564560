import { BallTriangle } from 'react-loader-spinner';



const Loader = () => {

  return (
    <div className="loader">
      <BallTriangle
        height="100"
        width="100"
        color="grey"
        ariaLabel="loading"
      />
    </div>
  );
};

export { Loader };