import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import GlobalStyles from './components/Styles/GlobalStyles';
import theme from './theme';
import routes from './routes';
import TokenStorage from './API/TokenStorage';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/auth';
import { useGet } from './API/request';
import { ModalBoxProvider } from './components/ModalBox';
const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getU = useGet();
  useEffect(() => {
    getU(`users/current`)
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch(setUser(resp.data.user));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getU;
  }, []);

  if (
    window.location.pathname.includes('app') &&
    TokenStorage.getAccessToken() === ''
  ) {
    navigate('/login', { replace: true });
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ModalBoxProvider>{content}</ModalBoxProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
