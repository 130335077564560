import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { Alert } from '@material-ui/core';

const Context = React.createContext();

const useModalBox = () => {
  const { setConfirmItem } = useContext(Context);

  return ({
    isVisible = false,
    headerTitle = '',
    nameTitle = '',
    descTitle = '',
    buttonTitle = '',
    onClick = () => {}
  }) => {
    setConfirmItem({
      isVisible,
      headerTitle,
      nameTitle,
      descTitle,
      buttonTitle,
      onClick
    });
  };
};

const ModalBoxProvider = ({ children }) => {
  const [fileName, setFileName] = useState('');
  const [desc, setDesc] = useState('');
  const [error, setError] = useState('');
  

  const [modalBoxItem, setModalBoxItem] = useState({
    isVisible: false,
    headerTitle: '',
    nameTitle: '',
    descTitle: '',
    buttonTitle: '',
    onClick: () => {},
  });

  const onClose = () => {
    setModalBoxItem({
      isVisible: false,
      headerTitle: '',
      nameTitle: '',
      descTitle: '',
      buttonTitle: '',
      onClick: () => {}
    });
  };

  const onSave = () => {
    console.log('save')
    if (fileName !== '') {
      setModalBoxItem({ isVisible: false });
      modalBoxItem.onClick(fileName, desc, () => {
        setDesc('')
        setFileName('')
      },
    (error) => {
      setError(error)
    }
    );
    } else {
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', onClose);
    return () => {
      window.removeEventListener('popstate', onClose);
    };
  }, []);

  return (
    <Context.Provider value={{ setConfirmItem: setModalBoxItem }}>
      {modalBoxItem.isVisible && (
        <>
        {error && error}
          <div className={styles.darkBG}>
            <div className={styles.centered}>
              <div className={styles.modal}>
                <div className={styles.modalHeader}></div>
                <button className={styles.closeBtn} onClick={onClose}>
                  {<CancelIcon />}
                </button>
                <h5 className={styles.inputHeader}>{modalBoxItem.nameTitle}</h5>
                <div className={styles.modalContent}>
                  <input
                    type="text"
                    name="fileName"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    style={{
                      height: 30,
                      fontSize: 15,
                      padding: 5,
                      border: '1px solid black'
                    }}
                  />
                </div>
                <div style={{ margin: 10 }} />
                <h5 className={styles.inputHeader}>{modalBoxItem.descTitle}</h5>
                <div className={styles.modalContent}>
                  <input
                    type="text"
                    name="desc"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    style={{
                      height: 30,
                      fontSize: 15,
                      padding: 5,
                      border: '1px solid black'
                    }}
                  />
                </div>
                <div className={styles.modalActions}>
                  <div className={styles.actionsContainer}>
                    <button
                      className={
                        /[a-zA-zа-яА-я]/.test(fileName)
                          ? styles.saveBtnEnabled
                          : styles.saveBtnDisabled
                      }
                      onClick={onSave}
                      disabled={
                        /[a-zA-zа-яА-я]/.test(fileName)
                          ? false
                          : true
                      }
                    >
                      {modalBoxItem.buttonTitle}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {children}
    </Context.Provider>
  );
};

export { ModalBoxProvider, useModalBox };
