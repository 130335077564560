import React, { useCallback } from 'react';
import { Button, Box } from '@material-ui/core';
import { useGet } from '../../API/request';
import { apiUrl } from '../../API/environment';
const Reports = () => {
  const getU = useGet();
  const handleExport = useCallback(async () => {
    try {
      const response = await getU('download/completed', {
        responseType: 'json' // Указываем, что ожидаем JSON в ответе
      });

      const fileUrl = response.data.file;
      console.log(fileUrl); // Получаем URL файла
      window.open(apiUrl + fileUrl, '_blank');
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px'
      }}
    >
      <Button variant="outlined" onClick={handleExport}>
        Экспортировать заказы
      </Button>
    </Box>
  );
};

export default Reports;
