import Reports from '../../components/reports/reports';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
const Orders = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>Заказы</title>
      </Helmet>
      <Reports />
    </Box>
  );
};

export default Orders;
