import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Checkbox,
  ListItemText,
  FormControlLabel
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { workingScheduleOutside, roles } from '../../../../Constants';
import { Loader } from '../../../../components/Loader/Loader';
import { useAddOutsideMaster } from './useAddOutsideMaster';
import { AlertForm } from '../../../../components/AlertForm/AlertForm';
import { GoBack } from '../../../../components/GoBack/GoBack';
import { HelmetMain } from '../../../../components/Helmet/Helmet';
import '../../../../styles/All.css';


const MasterOutsideAdd = () => {
  const {
    tags,
    alert,
    cities,
    handleChange,
    handleChangePhone,
    isLoaded,
    submitDisabled,
    submit,
    errors,
    values,
    setValues,
  } = useAddOutsideMaster();


  if (isLoaded) return (<Loader/>);

  return (
    <>
      <HelmetMain title='Добавление нового мастера' />
      <GoBack />

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          mb: 5
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление нового мастера" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Имя пользователя"
                    margin="normal"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    error={errors.username}
                  />
                  <TextField
                    fullWidth
                    label="ФИО пользователя"
                    margin="normal"
                    name="fio"
                    onChange={handleChange}
                    type="text"
                    value={values.fio}
                    variant="outlined"
                    error={errors.fio}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    name="Phone number"
                    onChange={handleChangePhone}
                    type="text"
                    placeholder="+7 (900)-000-00-00"
                    value={values.phone}
                    variant="outlined"
                    error={errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="Telegram"
                    margin="normal"
                    name="telegram"
                    onChange={handleChange}
                    type="telegram"
                    value={values.telegram}
                    variant="outlined"
                    error={errors.telegram}
                  />
                  <TextField
                    fullWidth
                    label="Whats app"
                    margin="normal"
                    name="whatsApp"
                    onChange={handleChange}
                    value={values.whatsApp}
                    variant="outlined"
                    error={errors.whatsApp}
                  />
                  <TextField
                    fullWidth
                    label="Общая компенсация"
                    margin="normal"
                    name="generalCompensation"
                    onChange={handleChange}
                    type="number"
                    value={values.generalCompensation ? values.generalCompensation : ''}
                    variant="outlined"
                    error={errors.generalCompensation}
                    inputProps={{
                      min: '0',
                      max: '100000'
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Рабочий график</InputLabel>
                    <Select
                      labelId="Schedule"
                      name="schedule"
                      value={values.schedule}
                      label="Рабочий график"
                      onChange={handleChange}
                    >
                      {workingScheduleOutside?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: 250, mr: 5 }}
                    margin="normal"
                    id="beginSchedule"
                    label="Дата начала отсчета графика"
                    type="date"
                    name="beginSchedule"
                    value={values.beginSchedule}
                    error={errors.beginSchedule}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '9999-12-31'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeBegin"
                    label="Время начала"
                    type="time"
                    name="timeBegin"
                    onChange={handleChange}
                    value={values.timeBegin ? values.timeBegin : ''}
                    error={errors.timeBegin}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeEnd"
                    label="Время окончания"
                    type="time"
                    name="timeEnd"
                    onChange={handleChange}
                    value={values.timeEnd ? values.timeEnd : ''}
                    error={errors.timeEnd}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '1'
                    }}
                  />
                  <TextField
                    sx={{ width: 100, mr: 5 }}
                    margin="normal"
                    id="bet"
                    label="Процент %"
                    type="number"
                    name="bet"
                    onChange={handleChange}
                    value={values.bet ? values.bet : ''}
                    error={errors.bet}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 175, mr: 5 }}
                    margin="normal"
                    id="lift_compensation"
                    label="Процент с допродаж %"
                    type="number"
                    name="lift_compensation"
                    onChange={handleChange}
                    value={values.lift_compensation ? values.lift_compensation : ''}
                    error={errors.lift_compensation}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />


                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="cities">Города</InputLabel>
                    <Select
                      labelId="cities"
                      name="cities"
                      value={values.cities}
                      label="Города"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                      error={errors.cities}
                    >
                      {cities?.map((city, index) => (
                        <MenuItem value={city} key={index}>
                          <Checkbox
                            checked={
                              city === values.cities.find((el) => el === city)
                            }
                          />
                          <ListItemText primary={city} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="tags">Навыки</InputLabel>
                    <Select
                      labelId="tags"
                      name="tags"
                      value={values.tags}
                      label="Навыки"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                      error={errors.tags}
                    >
                      {tags?.map((tag, i) => (
                        <MenuItem value={tag} key={i}>
                          <Checkbox
                            checked={
                              tag === values.tags.find((el) => el === tag)
                            }
                          />
                          <ListItemText primary={tag} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.full_time}
                        onChange={() => setValues((prev) => ({ ...prev, full_time: !prev.full_time}))}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Не учитывать временной диапазон"
                    labelPlacement="bottom"
                  />
                <Divider />
                  <AlertForm isVisible={alert.isVisible} text={alert.txt} type={alert.type}  />
                </CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled || alert.isVisible}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MasterOutsideAdd;
