export const logging = ({ type, message, userId, additionalContext }) => {
  /*var obj = {
    type: type,
    message: message,
    context: context,
  };*/

  const obj = {
    project_id: String(3),
    type: type,
    message: message,
    user_id: String(userId),
    device_id: "",
    additional_context: additionalContext,
  };

  const xhr = new XMLHttpRequest();

  //xhr.open("POST", "https://cyberstorm.pro/logr/", true); - common logs
  //xhr.open("POST", "https://cyberstorm.pro/logrp/", true); - logs for client

  xhr.open(
    "POST",
    "https://log.backend.webteamstorm.ru/api/ajax-add-project-log",
    true
  );

  /*xhr.onload = function () {
    console.log(xhr.responseText);
  };*/

  //console.log(JSON.stringify(xhr.onload));

  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(JSON.stringify(obj));
};
