import { Box, TextField, Button } from '@material-ui/core';
const SearchMasters = ({
  queryParams,
  handleChangeQueryParams,
  handleFilterQueryParams
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mx: 2,
        mb: 1
      }}
    >
      <TextField
        fullWidth
        label="Id"
        margin="normal"
        name="id"
        onChange={handleChangeQueryParams}
        type="text"
        value={queryParams.id}
        variant="outlined"
        style={{ width: '20%' }}
      />
      <TextField
        fullWidth
        label="User name"
        margin="normal"
        name="username"
        onChange={handleChangeQueryParams}
        type="text"
        value={queryParams.username}
        variant="outlined"
        style={{ width: '20%' }}
      />
      {/* <TextField
        fullWidth
        label="FIO"
        margin="normal"
        name="fio"
        onChange={handleChangeQueryParams}
        type="text"
        value={queryParams.fio}
        variant="outlined"
        style={{ width: '20%' }}
      /> */}
      <TextField
        fullWidth
        label="Region"
        margin="normal"
        name="email"
        onChange={handleChangeQueryParams}
        type="text"
        value={queryParams.email}
        variant="outlined"
        style={{ width: '20%' }}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={handleFilterQueryParams}
        sx={{ mt: 2, mb: 1 }}
      >
        Применить
      </Button>
    </Box>
  );
};

export default SearchMasters;
