import React, { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
//import ReactQuill from 'react-quill';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/Avatar/style.css';
//import { Quill } from 'react-quill/lib';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiUrl } from '../../API/environment';
import { useSelector } from 'react-redux';

const MessageEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getI = useGet();
  const putI = usePut();
  const user = useSelector((state) => state.auth.user);
  console.log(user);
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);
  const [uploadUrl] = useState(apiUrl + 'api/worker/CKEditorUpload');

  /*Functionality that was used with Quill. Right now CKEditor in use instead*/
  /*const fontSizeArr = [
    '8pt',
    '9pt',
    '10pt',
    '12pt',
    '14pt',
    '16pt',
    '20pt',
    '24pt',
    '32pt',
    '42pt',
    '54pt',
    '68pt',
    '84pt',
    '98pt'
  ];

  var Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);

  var toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons

    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ size: fontSizeArr }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image'],

    ['clean'] // remove formatting button
  ];*/

  const [values, setValues] = useState({
    title: '',
    data: '',
    recipient: null
  });
  const [errors, setErrors] = useState({
    title: false,
    data: false,
    recipient: false
  });

  const [instruction, setInstruction] = useState([]);

  const valueForSelect = useMemo(() => {
    if (values.recipient === 0) return 'Всем';
    else if (values.recipient === 1) return 'Менеджеры';
    else if (values.recipient === 2) return 'Исполнители';
    else return '';
  }, [values.recipient]);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validateInfo = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.title === '') {
      validComplete = false;
      formErrors.title = false;
      showAlert('error', 'Поле Заголовок Сообщения не должно быть пустым');
    }
    if (values.data === '') {
      validComplete = false;
      formErrors.data = false;
      showAlert('error', 'Поле Содержимое не должно быть пустым');
    }
    if (values.recipient === null) {
      validComplete = false;
      formErrors.recipient = false;
      showAlert('error', 'Поле Получатель не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const setNewRecipient = (newValue) => {
    if (newValue === 'Менеджеры')
      setValues((prev) => ({ ...prev, recipient: 1 }));
    else if (newValue === 'Исполнители')
      setValues((prev) => ({ ...prev, recipient: 2 }));
    else if (newValue === 'Всем')
      setValues((prev) => ({ ...prev, recipient: 0 }));
  };

  const submitInfo = async () => {
    if (validateInfo()) {
      setSubmitDisabled(true);

      const data = {
        title: values.title,
        data: values.data,
        recipient: values.recipient
      };

      console.log(data);

      putI(`message/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Данные успешно обновленны');
          } else {
            showAlert('error', `Ошибка: ${resp.message}`);
          }
        })
        .catch((err) => {
          showAlert('error', `Ошибка сервера: ${err.message}`);
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    getI(`message `)
      .then((resp) => {
        if (resp.status === 'success') {
          setValues(...resp.data.Message.filter((el) => el.id === Number(id)));
          setInstruction(resp.data.Message);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Редактировать сообщение</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Сообщения
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Сообщения</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <Box sx={{ mb: 1 }}>
              <Alert
                severity={alert.type}
                style={{ display: alert.isVisible ? 'flex' : 'none' }}
              >
                {alert.txt}
              </Alert>
            </Box>
            <Box
              sx={{
                paddingBottom: 1
              }}
            ></Box>
            <form>
              <Card>
                <CardHeader title="Редактировать сообщение" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Title"
                    margin="normal"
                    name="title"
                    onChange={(event) =>
                      setValues((prevValue) => ({
                        ...prevValue,
                        title: event.target.value
                      }))
                    }
                    type="text"
                    variant="outlined"
                    value={values.title}
                    error={errors.title}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Получатель</InputLabel>
                    <Select
                      labelId="Schedule"
                      label="Получатель"
                      onChange={(event) => setNewRecipient(event.target.value)}
                      value={valueForSelect}
                    >
                      {user.role !== 'executive_manager' && (
                        <>
                          <MenuItem value={'Всем'}>Всем</MenuItem>

                          <MenuItem value={'Менеджеры'}>Менеджеры</MenuItem>
                        </>
                      )}
                      <MenuItem value={'Исполнители'}>Исполнители</MenuItem>
                    </Select>
                  </FormControl>
                  {/*<ReactQuill
                    modules={{
                      toolbar: toolbarOptions
                    }}
                    theme="snow"
                    style={{ height: '100%', marginTop: '15px' }}
                    onChange={(value) =>
                      setValues((prevValue) => ({ ...prevValue, data: value }))
                    }
                    value={values.data}
                  />*/}
                  <CKEditor
                    config={{
                      ckfinder: {
                        uploadUrl: uploadUrl
                      }
                    }}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      console.log(editor.getData());
                      setValues((prevValue) => ({
                        ...prevValue,
                        data: String(editor.getData())
                      }));
                    }}
                    data={values.data}
                  />
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitInfo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MessageEdit;
